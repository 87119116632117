import React from "react";
import "../Assets/Style/Gallary.css";
import { IMAGES } from "../Utils";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Gallary = () => {
  return (
    <>
      <div className="gallary-section">
        <h3>Gallary</h3>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <AnimationOnScroll animateIn="animate__fadeInLeftBig">
                <div className="gallary-wrapper">
                  <img src={IMAGES.VideoImg} alt="" />
                  <p>Celebration</p>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <AnimationOnScroll animateIn="animate__fadeInLeftBig">
                <div className="gallary-wrapper">
                  <img src={IMAGES.VideoImg} alt="" />
                  <p>Celebration</p>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <AnimationOnScroll animateIn="animate__fadeInLeftBig">
                <div className="gallary-wrapper">
                  <img src={IMAGES.VideoImg} alt="" />
                  <p>Celebration</p>
                </div>
              </AnimationOnScroll>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12">
            <AnimationOnScroll animateIn="animate__fadeInRightBig">
              <div className="gallary-wrapper">
                <img src={IMAGES.VideoImg2} alt="" />
                <p>Celebration</p>
                
              </div>
              </AnimationOnScroll>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
            <AnimationOnScroll animateIn="animate__fadeInRightBig">
              <div className="gallary-wrapper">
                <img src={IMAGES.VideoImg} alt="" />
                <p>Celebration</p>
              </div>
              </AnimationOnScroll>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12">
            <AnimationOnScroll animateIn="animate__fadeInRightBig">
              <div className="gallary-wrapper">
                <img src={IMAGES.VideoImg2} alt="" />
                <p>Celebration</p>
              </div>
              </AnimationOnScroll>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2 col-md-12 col-sm-12">
              <div className="gallary-wrapper">
                <img src={IMAGES.VideoImg2} alt="" />
                <p>Celebration</p>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="gallary-wrapper">
                <img src={IMAGES.Capability} alt="" />
                <p>Celebration</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-12 col-sm-12">
              <div className="gallary-wrapper">
                <img src={IMAGES.VideoImg2} alt="" />
                <p>Celebration</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="gallary-wrapper">
                <img src={IMAGES.Compition} alt="" />
                <p>Celebration</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12">
              <div className="gallary-wrapper">
                <img src={IMAGES.VideoImg2} alt="" />
                <p>Celebration</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12">
              <div className="gallary-wrapper">
                <img src={IMAGES.VideoImg2} alt="" />
                <p>Celebration</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallary;
