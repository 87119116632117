export const seoTitle = {
  Home: `Digital Marketing Agency in Pune | Web Development|Web Design|Graphic design in Pune`,
  About:` Digital Marketing Agency in Pune | Web Development, Web Design, Graphic design in Pune`,
  Services:` Digital Marketing Company in Pune | Drive Online Success with Expert Solutions`,
  Product :` Pune's Premier School Management Software | Revolutionize Education`,
  IT_Training:`Digital Marketing Agency in Pune | Web Development, Web Design, Graphic design in Pune`,
  Insights:`Digital Marketing Agency in Pune | Web Development, Web Design, Graphic design in Pune`,
  Contact:`Digital Marketing Agency in Pune | Web Development, Web Design, Graphic design in Pune`,
  WebDesign:`Website Design Company in Pune|DThrill`,
  WebDevelop:`Web Development Services Company in Pune| DThrill`,
  GraphicsDesign:`Professional Graphic Design Services in Pune| DThrill`,
  MobileApp:`Best Mobile App Development Services | DThrill`,
  ITcorporate:`Boost Your Team's Skills with Top-notch IT Corporate Training `,
  Schoolmanage:`Pune's Premier School Management Software | Revolutionize Education`,
  SPAmanage:`SPA Management Products in Pune | Spa Management Software | DThrill`,
  Hospitalmanage:`Hospital Management Services in Pune | Optimizing Healthcare Operations`,
  Inventory:`Efficient Inventory Management in Pune | Inventory Control`,
  Payroll:` Payroll Management Products in Pune | Simplify Payroll Processing`,
  digitalMarking:`Digital Marketing Company in Pune | DThrill`,
};



export const seoDecription ={
    Home:`DThrill is the best digital marketing Agency in pune, Maharashtra, that offers web development, web design, Search Engine Optimization (SEO), Social Media Marketing (SMM) & Pay Per Click (PPC) for small & medium sized businesses. 100% Best services are provided in pune, india.`,
    Service:`Boost your online presence with top-notch digital marketing Company in Pune. Our experts specialize in SEO, SEM, social media, and more. Get results today!`,
    WebDesign:`DThrill is Pune's fastest-growing, award-winning website design company. With a passion for innovation and a commitment to excellence, we craft stunning, user-friendly websites that captivate audiences and drive business success.`,
    WebDevelop:`Excellent web development services in Pune that deliver stunning websites as per your needs. Our expert team specializes in website design and creation to make your business thrive online. Contact us today for innovative web solutions!`,
    GraphicsDesign:`Our graphic design services breathe life into your brand, delivering eye-catching logos, stunning marketing materials, and captivating visuals that leave a lasting impression. Elevate your brand with us today!`,
    MobileApp:`levate your business with top-notch mobile app development services in pune. Our skilled iOS and Android app developers create customized solutions to bring your ideas to life. Contact now!`,
    ITcorporate:` Description:DThrill offers expert-led courses in various IT Corporate Training, empowering your team with the knowledge and skills they need to excel in today's technology-driven world. Boost Your Team's Skills with Top-notch IT Corporate Training`,
  Schoolmanage:`In pune Your school's efficiency and organization with our school management software. Manage student records, schedules, attendance, and more seamlessly. Try it today for a smarter and more effective educational experience!`,
  SPAmanage:`Discover top-quality SPA management products in Pune to streamline your spa operations. From software solutions to equipment, find everything you need to elevate your spa business.`,
  Hospitalmanage:`Empower your hospital with our state-of-the-art management software. From patient records to staff scheduling and billing, our system streamlines operations for better patient care and efficient administration.`,
  Inventory:`Simplify, optimize, and take control of your inventory effortlessly. Our inventory management software helps you track, organize, and streamline your stock, ensuring accuracy and cost-effectiveness in pune. `,
  Payroll:` Explore reliable payroll management products in Pune to streamline your payroll processing. Find solutions that make payroll tasks hassle-free and error-free.`,
  digitalMarking:`Boost your online presence with top-notch digital marketing Company in Pune. Our experts specialize in SEO, SEM, social media, and more. Get results today!`,


}



export const seoKeywords ={
    Home:`digital marketing company in pune,digital marketing agency in india,online marketing company in pune,internet marketing company in pune,top digital marketing company in india,
            online advertising agencies in pune,internet marketing service in pune,digital marketing agency in pune,
            best digital marketing agency in india,digital advertising agencies in pune,website Development Company in pune,
            Best website Development in pune,website design company in pune,Best website Design Compnay in pune,
            graphic design company in Pune,best graphic design Compay in pune`,
    Service:`digital marketing services near me,digital marketing services in Pune,
             digital marketing company near me, Digital marketing Company in Pune, online marketing solutions,
             SEO, SEM, social media marketing service near me, digital advertising, internet marketing,
             online presence, digital strategy, digital marketing agency near me, online marketing near me`,
    WebDesign:`web design company in india,website design company in pune, web design company near me, web design company,web design company in pune,web design company in pune, website design company near me,web design services,`,
    WebDevelop:`Web Development Company Pune,Web Development Services near me,web development company in pune,Software Development Company India,Custom Software Development Company Pune,Web Design Development Pune,Web Design Service, Professional Website Designing,Website development Services,Web Development Pune, Mobile App Development Pune`,
    GraphicsDesign:`Graphic design services in near me,Graphic design services in pune,Professional graphic designers,Creative graphic design,Logo design,Visual branding solutions,Custom graphic design,Graphic design agency,Illustration services,Print design,Digital graphic design,Corporate branding,Marketing collateral design,Infographic design,Brochure design,Flyer design,Poster design`,
    MobileApp:`Mobile app development near me,iOS app development near me,Android app development near me,Android app development,Custom app development,App development services,Mobile app developers,Mobile app solutions,iOS developers in Pune,Android developers in Pune,Mobile app consultation`,
    ITcorporate:`IT Corporate Training,Corporate IT Courses,Professional IT Training,Advanced IT Skills,IT Training Programs,Technology Workshops,Corporate Learning Solutions,IT Skill Development,Employee Training Services,IT Corporate Training near me,IT Corporate Training in Pune`,
  Schoolmanage:`School Management Software,School Management Software near me,School Management 
  Product near me, School Management Software  in Pune,Education Management System,School
   Administration Tool,Student Information System,School ERP (Enterprise Resource Planning),Campus 
   Management Software,Academic Management Solution,Teacher and Student Portal,Online School 
   Records,School Attendance Software,Fee Management System,Parent-Teacher Communication,Grading 
   and Examination Software,Timetable and Scheduling Software,School Data Analytics,Digital School 
   Records,Cloud-based School Management,Mobile School App,Streamlined School Operations,School 
   Software Integration`,
  SPAmanage:`Spa Management Software near me,Spa Management Software in Pune,Spa Management Software,Salon and Spa Management Solutions,Spa POS System,Spa Inventory Management,Spa Appointment Booking,Spa Client Management,Spa Membership Software,Spa Billing and Invoicing,Spa CRM (Customer Relationship Management),Spa Analytics and Reporting,Spa Marketing Tools,Spa Business Management Software,Spa POS Hardware,Spa Point of Sale Solutions,Spa Software Integration`,
  Hospitalmanage:`Hospital Management Software near me,Hospital Management Software in Pune,Hospital Management Software,Healthcare Information System,Hospital ERP Solutions,Medical Practice Management Software,Clinical Management System,Electronic Health Records (EHR),Patient Registration System,Hospital Inventory Management,Healthcare Analytics Tools,Medical Billing and Coding Software,EMR (Electronic Medical Records) Software,Patient Scheduling Software,Healthcare Workflow Automation,Hospital,Administration Software,Healthcare Compliance Solutions,Health Information Exchange (HIE),Hospital Financial Management,Medical Record Keeping Software,Hospital Billing Software,Healthcare Data Security Solutions`,
  Inventory:`Inventory management software near me,Inventory management software in Pune,Inventory management software,Inventory tracking system,Stock control solutions,Warehouse management tools,Inventory optimization software,Inventory tracking software,Inventory management solutions,Inventory control software,Stock management software,Inventory software for businesses,Inventory management systems,Inventory tracking and control,Inventory tracking solutions,Inventory management apps,Inventory software solutions,Warehouse inventory software,Retail inventory management`,
  Payroll:`Payroll Services, Payroll Software, Payroll Processing, Payroll Outsourcing, Payroll Solutions, Payroll System, Payroll Administration,Payroll Automation, Employee Payroll, Small Business Payroll, HR and Payroll Management, Payroll Tax Management, Payroll Reporting, Payroll Calculations, Payroll Cost Reduction, Payroll Data Security, Payroll Accuracy`,
  digitalMarking:`digital marketing services near me,digital marketing services in Pune,digital marketing company near me, Digital marketing Company in Pune, online marketing solutions, SEO, SEM, social media marketing service near me, digital advertising, internet marketing, online presence, digital strategy, digital marketing agency near me, online marketing near me`,



}