import React, { useState } from "react";
import "../Assets/Style/WeSpecializeIn.css";
import { IMAGES } from "../Utils";
import { AnimationOnScroll } from "react-animation-on-scroll";

const WeSpecializeIn = () => {
  const [show, setShow] = useState(true);
  const [isHoveringHealthcare, setIsHoveringHealthcare] = useState(false);
  const [isHoveringEducation, setIsHoveringEducation] = useState(false);
  const [isHoveringPayroll, setIsHoveringPayroll] = useState(false);
  const [isHoveringInventory, setIsHoveringInventory] = useState(false);
  const [isHoveringEcommerce, setIsHoveringEcommerce] = useState(false);
  const [isHoveringWebsite, setIsHoveringWebsite] = useState(false);

  const handleMouseOverHealthcare = () => {
    setIsHoveringHealthcare(true);
    setShow(false);
  };

  const handleMouseOutHealthcare = () => {
    setIsHoveringHealthcare(false);
    setShow(true);
  };

  const handleMouseOverEducation = () => {
    setIsHoveringEducation(true);
    setShow(false);
  };

  const handleMouseOutEducation = () => {
    setIsHoveringEducation(false);
    setShow(true);
  };

  const handleMouseOverPayroll = () => {
    setIsHoveringPayroll(true);
    setShow(false);
  };

  const handleMouseOutPayroll = () => {
    setIsHoveringPayroll(false);
    setShow(true);
  };

  const handleMouseOverInventory = () => {
    setIsHoveringInventory(true);
    setShow(false);
  };

  const handleMouseOutInventory = () => {
    setIsHoveringInventory(false);
    setShow(true);
  };

  const handleMouseOverEcommerce = () => {
    setIsHoveringEcommerce(true);
    setShow(false);
  };

  const handleMouseOutEcommerce = () => {
    setIsHoveringEcommerce(false);
    setShow(true);
  };

  const handleMouseOverWebsite = () => {
    setIsHoveringWebsite(true);
    setShow(false);
  };

  const handleMouseOutWebsite = () => {
    setIsHoveringWebsite(false);
    setShow(true);
  };
  return (
    <>
      <div className="special-section">
        {/* <div className="container"> */}
        <div className="special-heading">
          <AnimationOnScroll animateIn="animate__zoomIn">
            <h3>We Specialize In</h3>
          </AnimationOnScroll>
          <p className="special-para">
            Our technology is very scalable. Our software can accommodate
            enormous numbers of clients. It's a marvelous opportunity. We'll
            keep developing products.
          </p>
        </div>

        <div className="special-wrapper">
          <div className="row speacialize-wrapper">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="we-left-main-wrapper">
                <AnimationOnScroll animateIn="animate__zoomIn">
                  <div className="we-left-wrapper">
                    {show && (
                      <img
                        src={IMAGES.HealthcareImg}
                        alt=""
                        className="left-special-icon"
                      />
                    )}
                    {isHoveringHealthcare && (
                      <img
                        src={IMAGES.HealthcareImg}
                        alt=""
                        className="left-special-icon"
                      />
                    )}
                    {isHoveringEducation && (
                      <img
                        src={IMAGES.EducationImg}
                        alt=""
                        className="left-special-icon"
                      />
                    )}
                    {isHoveringPayroll && (
                      <img
                        src={IMAGES.PayrollImg}
                        alt=""
                        className="left-special-icon"
                      />
                    )}
                    {isHoveringInventory && (
                      <img
                        src={IMAGES.InventoryImg}
                        alt=""
                        className="left-special-icon"
                      />
                    )}
                    {isHoveringEcommerce && (
                      <img
                        src={IMAGES.ECommerceImg}
                        alt=""
                        className="left-special-icon"
                      />
                    )}
                    {isHoveringWebsite && (
                      <img
                        src={IMAGES.WebsitesImg}
                        alt=""
                        className="left-special-icon"
                      />
                    )}
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <AnimationOnScroll animateIn="animate__fadeIn" delay={100}>
                    <div
                      className="outer-section"
                      onMouseOver={handleMouseOverHealthcare}
                      onMouseOut={handleMouseOutHealthcare}
                    >
                      <div className="special-single-section odd">
                        <div className="single-wrap">
                          <div>
                            <img
                              src={IMAGES.Healthcare}
                              alt=""
                              className="special-icon"
                            />
                            <div>
                              <p className="special-single-heading">
                                Healthcare
                              </p>
                              <p className="special-single-sub">
                                Helping you manage Healthcare
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  </AnimationOnScroll>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <AnimationOnScroll animateIn="animate__fadeIn" delay={200}>
                    <div
                      className="outer-section"
                      onMouseOver={handleMouseOverEducation}
                      onMouseOut={handleMouseOutEducation}
                    >
                      <div className="special-single-section even">
                        <div className="single-wrap">
                          <div>
                            <img
                              src={IMAGES.Education}
                              alt=""
                              className="special-icon"
                            />
                            <div>
                              <p className="special-single-heading">
                                Education
                              </p>
                              <p className="special-single-sub">
                                Student Information Base Software
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <AnimationOnScroll animateIn="animate__fadeIn" delay={300}>
                    <div
                      className="outer-section"
                      onMouseOver={handleMouseOverPayroll}
                      onMouseOut={handleMouseOutPayroll}
                    >
                      <div className="special-single-section odd">
                        <div className="single-wrap">
                          <div>
                            <img
                              src={IMAGES.Payroll}
                              alt=""
                              className="special-icon"
                            />
                            <div>
                              <p className="special-single-heading">Payroll</p>
                              <p className="special-single-sub">
                                Setting the standard in payroll Software
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <AnimationOnScroll animateIn="animate__fadeIn" delay={100}>
                    <div
                      className="outer-section"
                      onMouseOver={handleMouseOverInventory}
                      onMouseOut={handleMouseOutInventory}
                    >
                      <div className="special-single-section even">
                        <div className="single-wrap">
                          <div>
                            <img
                              src={IMAGES.Inventory}
                              alt=""
                              className="special-icon"
                            />
                            <div>
                              <p className="special-single-heading">
                                Inventory
                              </p>
                              <p className="special-single-sub">
                                Keep a constant eye your inventory
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <AnimationOnScroll animateIn="animate__fadeIn" delay={200}>
                    <div
                      className="outer-section"
                      onMouseOver={handleMouseOverEcommerce}
                      onMouseOut={handleMouseOutEcommerce}
                    >
                      <div className="special-single-section odd">
                        <div className="single-wrap">
                          <div>
                            <img
                              src={IMAGES.ECommerce}
                              alt=""
                              className="special-icon"
                            />
                            <div>
                              <p className="special-single-heading">
                                E-Commerce
                              </p>
                              <p className="special-single-sub">
                                Simplify ecommerce web presence.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <AnimationOnScroll animateIn="animate__fadeIn" delay={300}>
                    <div
                      className="outer-section"
                      onMouseOver={handleMouseOverWebsite}
                      onMouseOut={handleMouseOutWebsite}
                    >
                      <div className="special-single-section even">
                        <div className="single-wrap">
                          <div>
                            <img
                              src={IMAGES.Websites}
                              alt=""
                              className="special-icon"
                            />
                            <div>
                              <p className="special-single-heading">Websites</p>
                              <p className="special-single-sub">
                                High Quality & Engaging Design
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default WeSpecializeIn;
