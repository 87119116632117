import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";
// import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./Layout/Header";
import FooterComponent from "./Layout/FooterComponent";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import WebDesign from "./Services/WebDesign";
import GraphicDesign from "./Services/GraphicDesign";
import Gallary from "./Insights/Gallary";
import WebDevelopment from "./Services/WebDevelopment";
import DigitalMarketing from "./Services/DigitalMarketing";
import Blog from "./Pages/Blog";
import Registration from "./Auth/Registration";
import ContactUs from "./Auth/ContactUs";
import ViewBlog from "./Pages/ViewBlog";
import ITTraining from "./Pages/ITTraining";
import Services from "./Pages/Services";
import Products from "./Pages/Products";
import RegisterationPopUp from "./Components/RegisterationPopUp";
import { useEffect } from "react";
import MobileApp from "./Services/MobileApp";
import ViewBlog_digitalM from "./Pages/ViewBlog_digitalM";
import ViewBlog_Affilition from "./Pages/ViewBlog_Affilition";
import ViewBlog_marketContent from "./Pages/ViewBlog_marketContent";
import ViewBlog_webdesign from "./Pages/ViewBlog_webdesign";
import ViewBlog_unlimiteddTraffic from "./Pages/ViewBlog_unlimiteddTraffic";
import ViewBlog_HowManyCourses from "./Pages/ViewBlog_HowManyCourses";

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    <RegisterationPopUp />;
  });
  return (
    <>
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/webdesign" element={<WebDesign />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/graphicdesign" element={<GraphicDesign />} />
        <Route path="/webdevelopment" element={<WebDevelopment />} />
        <Route path="/digitalmarketing" element={<DigitalMarketing />} />
        <Route path="/mobileappdevelopment" element={<MobileApp />} />
        <Route path="/gallary" element={<Gallary />} />
        <Route path="/gallary" element={<Registration />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/viewblog" element={<ViewBlog />} />
        <Route path="/ittraining" element={<ITTraining />} />
        <Route path="/services" element={<Services />} />
        <Route path="/products" element={<Products />} />
        <Route path="/popup" element={<RegisterationPopUp />} />

        <Route path="/totalCoursesInfo" element={<ViewBlog_HowManyCourses />} />
        <Route path="/viewDigital" element={<ViewBlog_digitalM />} />
        <Route path="/affiliate" element={<ViewBlog_Affilition />} />
        <Route path="/viewContentMarket" element={<ViewBlog_marketContent />} />
        <Route path="/viewwebdesign" element={<ViewBlog_webdesign />} />
        <Route
          path="/viewblogunlimited"
          element={<ViewBlog_unlimiteddTraffic />}
        />
      </Routes>
      <FooterComponent />
    </>
  );
}

export default App;
