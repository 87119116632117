import React from "react";
import { IMAGES } from "../Utils";
import {
  MdArrowBack,
  MdArrowForward,
  MdKeyboardArrowRight,
} from "react-icons/md";
import "../Assets/Style/Blog.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import RegisterationPopUp from "../Components/RegisterationPopUp";
import { Helmet } from "react-helmet";
import { seoTitle } from "../Utils/SEO";

const Blog = () => {
  const [showModal, setShowModal] = useState(false);
  // const handleClose = () => setShowModal(false);

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => setShowModal(true), 50000);
  };
  useEffect(() => {
    setTimeout(() => setShowModal(true), 50000);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const HowManyCourses = () => {
    navigate("/totalCoursesInfo");
  };

  const ViewDigital = () => {
    navigate("/ViewDigital");
  };

  const ViewAffiliate = () => {
    navigate("/affiliate");
  };

  const ViewMarketContent = () => {
    navigate("/viewContentMarket");
  };

  const ViewWebDesign = () => {
    navigate("/viewwebdesign");
  };

  const Viewunlimitedtraffic = () => {
    navigate("/viewblogunlimited");
  };
  return (
    <>
      <Helmet>
        <title>{seoTitle.Insights}</title>
        {/* <meta name="keywords" content={seoKeywords.Home} /> */}
        {/* <meta name="description" content={seoDecription.Home} /> */}
      </Helmet>
      <RegisterationPopUp
        show={showModal}
        setShow={setShowModal}
        handleClose={handleClose}
      />
      <div className="row blogsBanner">
        <div className="blogImgParallax">
          <div className="container blogText">
            <div>
              <h3>Blogs</h3>
              <h5>A directory of wonderful things</h5>
            </div>
          </div>
        </div>
      </div>
      <div className="Blog">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="blogRight">
                <div className="blogImage">
                  <img src={IMAGES.BlogHowMany} alt=" img for blog" />
                </div>
                <div className="blogContent">
                  <div className="profile">
                    {/* <p>DThrill Software Solutions LLP</p> */}
                    <p>Digital marketing</p>
                  </div>

                  <div className="blogTitle">
                    <h5>How Many Courses are there in Digital Marketing?</h5>
                  </div>

                  <div className="blogDescription">
                    The number of courses in digital marketing can vary widely
                    depending on the institution, platform, and the specific
                    focus of the course...
                  </div>
                  <div className="">
                    <button className="btnReadMore" onClick={HowManyCourses}>
                      <MdKeyboardArrowRight size={20} className="arrowIcon" />
                      <p>Read More </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="blogRight">
                <div className="blogImage">
                  <img src={IMAGES.BlogOuterDM} alt=" img for blog" />
                </div>
                <div className="blogContent">
                  <div className="profile">
                    {/* <p>DThrill Software Solutions LLP</p> */}
                    <p>Digital marketing</p>
                  </div>

                  <div className="blogTitle">
                    <h5>Digital marketing is essential for small companies</h5>
                  </div>

                  <div className="blogDescription">
                    You're a company owner? Super! wait, wait. Have you ever
                    been thinking where you're on the market? ...
                  </div>
                  <div className="">
                    <button className="btnReadMore" onClick={ViewDigital}>
                      <MdKeyboardArrowRight size={20} className="arrowIcon" />
                      <p>Read More </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" blogRight">
                <div className="blogImage">
                  <img src={IMAGES.BlogOuterWD} alt=" img for blog" />
                </div>
                <div className="blogContent">
                  <div className="profile">
                    {/* <p>DThrill Software Solutions LLP</p> */}
                    <p>Web Design</p>
                  </div>
                  <div className="blogTitle">
                    <h5>What Is Web Design?</h5>
                  </div>

                  <div className="blogDescription">
                    Web Design is the process of making websites and mobile
                    applications appealing to the target audience. The web
                    designer needs to be good at ...
                  </div>
                  <div className="">
                    <button className="btnReadMore" onClick={ViewWebDesign}>
                      <MdKeyboardArrowRight size={20} className="arrowIcon" />
                      <p>Read More </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" blogRight">
                <div className="blogImage">
                  <img src={IMAGES.BlogOuterAffilate1} alt=" img for blog" />
                </div>
                <div className="blogContent">
                  <div className="profile">
                    {/* <p>DThrill Software Solutions LLP</p> */}
                    <p>Digital marketing</p>
                  </div>
                  <div className="blogTitle">
                    <h5>
                      10 Highly Actionable Content Marketing Tips For More
                      Traffic
                    </h5>
                  </div>

                  <div className="blogDescription">
                    What Is Content Marketing? There are many sophisticated
                    definitions of content marketing, but my own simplified
                    version...
                  </div>
                  <div className="">
                    <button className="btnReadMore" onClick={ViewMarketContent}>
                      <MdKeyboardArrowRight size={20} className="arrowIcon" />
                      <p>Read More </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" blogRight">
                <div className="blogImage">
                  <img src={IMAGES.BlogOuterAffilate2} alt=" img for blog" />
                </div>
                <div className="blogContent">
                  <div className="profile">
                    {/* <p>DThrill Software Solutions LLP</p> */}
                    <p>Digital marketing</p>
                  </div>
                  <div className="blogTitle">
                    <h5>Affiliate Marketing Using Quora Full Case Study</h5>
                  </div>

                  <div className="blogDescription">
                    Affiliate marketing is the easiest way to make money online
                    without having any website or blog. Anyone can do this no
                    matter how smart you are, ...
                  </div>
                  <div className="">
                    <button className="btnReadMore" onClick={ViewAffiliate}>
                      <MdKeyboardArrowRight size={20} className="arrowIcon" />
                      <p>Read More </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" blogRight">
                <div className="blogImage">
                  <img src={IMAGES.BlogOuterAffilate3} alt=" img for blog" />
                </div>
                <div className="blogContent">
                  <div className="profile">
                    {/* <p>DThrill Software Solutions LLP</p> */}
                    <p>Digital marketing</p>
                  </div>
                  <div className="blogTitle">
                    <h5>
                      How to Send Unlimited Free Traffic To Your Clickbank links
                    </h5>
                  </div>

                  <div className="blogDescription">
                    Affiliate marketing is the easiest way to make money online
                    without having any website or blog. Anyone can do this no
                    matter how smart you are, ...
                  </div>
                  <div className="">
                    <button
                      className="btnReadMore"
                      onClick={Viewunlimitedtraffic}
                    >
                      <MdKeyboardArrowRight size={20} className="arrowIcon" />
                      <p>Read More </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6">
              <div className=" blogRight">
                <div className="blogImage">
                  <img src={IMAGES.Compition} alt=" img for blog" />
                </div>
                <div className="blogContent">
                  <div className="profile">
                    <p>DThrill Software Solutions LLP</p>
                    <p>Web Design</p>
                  </div>
                  <div className="blogTitle">
                    <h5>Affiliate Marketing Using Quora Full Case Study</h5>
                  </div>

                  <div className="blogDescription">
                    Affiliate marketing is the easiest way to make money online
                    without having any website or blog. Anyone can do this no
                    matter how smart you are, ...
                  </div>
                  <div className="">
                    <button className="btnReadMore">
                      <MdKeyboardArrowRight size={20} className="arrowIcon" />
                      <p>Read More </p>
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-4 col-md-6 blogLeft">
              <div className="stickyBlogleft">
                <div className="searchBlog">
                  <input
                    type="search"
                    placeholder="search ..."
                    className="search-field"
                  />
                  <button name="submit" type="submit" class="search-submit">
                    Search
                  </button>
                </div>
                <div className="row tags">
                  <h4>Tags</h4>
                  {tags.map((t, idx) => (
                    <div className="col-lg-4 col-md-6 bgTag">
                      <a>{t.tag}</a>
                    </div>
                  ))}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
