import React from "react";
import "../Assets/Style/WebDevelopment.css";
import { IMAGES } from "../Utils";
import { Carousel } from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ServicesContact from "../Pages/ServicesContact";
import { useState, useEffect } from "react";
import RegisterationPopUp from "../Components/RegisterationPopUp";
import { Helmet } from "react-helmet";
import { seoDecription, seoKeywords, seoTitle } from "../Utils/SEO";

const WebDevelopment = () => {
  const [showModal, setShowModal] = useState(false);
  // const handleClose = () => setShowModal(false);

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => setShowModal(true), 50000);
  };
  useEffect(() => {
    setTimeout(() => setShowModal(true), 50000);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>{seoTitle.WebDevelop}</title>
        <meta name="keywords" content={seoKeywords.WebDevelop} />
        <meta name="description" content={seoDecription.WebDevelop} />
      </Helmet>
      <RegisterationPopUp
        show={showModal}
        setShow={setShowModal}
        handleClose={handleClose}
      />
      <div className="">
        <div className="devCard-solution">
          <div className="devCard-wrapper">
            <div className="devCard-left">
              <div className="devCard-heading-section">
                {/* <p>DThrill - Optimal Solutions</p> */}
                <h3>We Excel In Delivering Optimal Solutions.</h3>
              </div>
              <Carousel controls={false}>
                <Carousel.Item>
                  <div className="devCard-left-wrapper">
                    <div className="devCard-inner-wrapper">
                      <img src={IMAGES.WebDevSecure} alt="" />

                      <h5>Secure Website Hosting</h5>
                      <p>
                        It’s important to have your site performing well, and
                        also be accessible to users 24/7 (without technical
                        difficulties). Let DThrill set up a hosting solution for
                        your new site that won’t disappoint your users or the
                        search engines. With trust playing a larger role for
                        users and search engines, secure websites are becoming
                        the norm. Ask us about implementing a security
                        certificate that will put your users’ minds at ease.
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="devCard-left-wrapper">
                    <div className="devCard-inner-wrapper">
                      <img src={IMAGES.WebDevProd} alt="" />

                      <h5>Product Engineering & Services</h5>
                      <p>
                        Develop and propose product improvements through
                        periodical and accurate testing, repairing & refining
                        every version. Our agency has worked with clients from a
                        variety of industries, to develop professional websites
                        that add value to their businesses. Our bespoke
                        templates ensure that your site stands out from the
                        rest.
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="devCard-left-wrapper">
                    <div className="devCard-inner-wrapper">
                      <img src={IMAGES.WebDevReach} alt="" />

                      <h5>Reach More Customers</h5>
                      <p>
                        Our digital marketing services can boost your online
                        presence by driving users to your site, optimising the
                        rate of conversions (and lowering marketing costs). Grow
                        your organic traffic with search engine optimisation
                        (SEO), or target users with marketing methods like email
                        marketing and Google Ads. These all work together to
                        drive the right traffic to you.
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="devCard-left-wrapper">
                    <div className="devCard-inner-wrapper">
                      <img src={IMAGES.WebDevTrust} alt="" />

                      <h5>Trust the Development Experts</h5>
                      <p>
                        With over a decade of industry experience to draw on,
                        our development services help clients meet their
                        business requirements. We value ongoing feedback from
                        our clients to help keep our standards high, and we
                        refuse to compromise on quality. For more information
                        about our web development solutions, contact our
                        full-service digital agency in London, and we’ll create
                        the perfect solution for your needs.
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
            <div className="marq-wrapper">
              <marquee direction="up">
                <img
                  src={IMAGES.Marquee1}
                  alt=""
                  scrollamount="20"
                  className="marq-img"
                />
              </marquee>
              <marquee direction="down">
                <img
                  src={IMAGES.Marquee1}
                  alt=""
                  scrollamount="20"
                  className="marq-img"
                />
              </marquee>
            </div>
          </div>
        </div>
        <div>
          <div className="dev-section">
            <div className="dev-wrapper">
              <AnimationOnScroll animateIn="animate__fadeIn">
                <img src={IMAGES.WebsiteDev} alt="" />
              </AnimationOnScroll>
              <div className="dev-para-wrapper-odd">
                <AnimationOnScroll animateIn="animate__zoomIn">
                  <div className="dev-para">
                    <h3>Website Development</h3>
                    <p>
                      From full eCommerce solutions like DThrill eCommerce
                      Platform, to information sites on popular platforms like
                      WordPress and Drupal, we can develop a custom solution to
                      fit your exact needs. Our cost-effective solutions are
                      created to perform optimally. Our agency has worked with
                      clients from a variety of industries, to develop
                      professional websites that add value to their businesses.
                      Our bespoke templates ensure that your site stands out
                      from the rest.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>

          <div className="dev-section">
            <div className="dev-wrapper-even">
              <div className="dev-para-wrapper-even">
                <AnimationOnScroll animateIn="animate__zoomIn">
                  <div className="dev-para">
                    <h3>Design Services Prioritising User Experience</h3>
                    <p>
                      It’s surprisingly rare to come across a website that is
                      beautifully designed and also technically sound. Our goal
                      is to create and develop websites that excel on both
                      fronts.
                    </p>
                    <p>
                      Through design and usability, we give your customers many
                      great reasons to explore your web pages for longer..
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
              <AnimationOnScroll animateIn="animate__fadeIn">
                <img src={IMAGES.UserExp} alt="" />
              </AnimationOnScroll>
            </div>
          </div>

          <div className="dev-section">
            <div className="dev-wrapper">
              <AnimationOnScroll animateIn="animate__fadeIn">
                <img src={IMAGES.TechExpertise} alt="" />
              </AnimationOnScroll>
              <div className="dev-para-wrapper-odd">
                <AnimationOnScroll animateIn="animate__zoomIn">
                  <div className="dev-para">
                    <h3>Experience and Technical Expertise</h3>
                    <p>
                      Our experienced team uses their technical and creative
                      knowledge to ensure that your website offers a faultless
                      user experience to customers.
                    </p>
                    <p>
                      From building bespoke themes, to creating bespoke
                      extensions to add specific functionality – we are
                      proficient at the latest web technologies, and specialise
                      in open-source platforms like DThrill Custom Platform,
                      WordPress and more.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>

          <div className="dev-section">
            <div className="dev-wrapper-even">
              <div className="dev-para-wrapper-even">
                <AnimationOnScroll animateIn="animate__zoomIn">
                  <div className="dev-para">
                    <h3>Mobile-Friendly Experiences</h3>
                    <p>
                      Users aren’t simply sitting behind keyboards anymore,
                      they’re also looking to access online information on the
                      go – using tablets, smartphones and other mobile devices.
                      A successful website must be compatible with mobile
                      platforms, or risk losing a large number of potential
                      customers.
                    </p>
                    <p>
                      DThrill focuses on developing mobile-friendly websites, to
                      ensure that searchers can easily find your company
                      anytime, anywhere, on any device.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
              <AnimationOnScroll animateIn="animate__fadeIn">
                <img src={IMAGES.MobileFriendly} alt="" />
              </AnimationOnScroll>
            </div>
          </div>
        </div>
        <ServicesContact />
      </div>
    </>
  );
};

export default WebDevelopment;
