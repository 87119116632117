const IMAGES = {
  Logo: require("../Assets/Images/logo.png"),

  // Slider1: require("../Assets/Images/slider-1.jpg"),
  // DMSlider: require("../Assets/Images/Slider-1 Digital Marketing.jpg"),
  WebDesignHomeCard: require("../Assets/Images/webDesignHomeCard.jpg"),
  WebDevHomeCard: require("../Assets/Images/WebDevelopmentHomeCard.jpg"),
  DigitalHomeCard: require("../Assets/Images/DigitalMarketingHomeCard.jpg"),
  GraphicsHomeCard: require("../Assets/Images/GraphicDesignHomeCard.jpg"),
  MbAppDevelopmentHomeCard: require("../Assets/Images/MbAppDevelopmentHomeCard.jpg"),
  ByIndustry: require("../Assets/Images/by-industry.avif"),
  ContactLady: require("../Assets/Images/contact-lady.png"),

  MbSlider: require("../Assets/Images/mb-slider-2.jpg"),
  Abstract1: require("../Assets/Images/abstract-2.png"),
  Abstract2: require("../Assets/Images/abstract-3.png"),

  WebDesign: require("../Assets/Images/web-design.png"),
  WebDesign2: require("../Assets/Images/web-design2.png"),
  WebDev: require("../Assets/Images/web-development.png"),
  WebDev2: require("../Assets/Images/web-development2.png"),
  Graphic: require("../Assets/Images/graphic-designing.png"),
  Graphic2: require("../Assets/Images/graphic-designing2.png"),
  Digital: require("../Assets/Images/digital-marketing.png"),
  Digital2: require("../Assets/Images/digital-marketing2.png"),
  Compition: require("../Assets/Images/compition.jpg"),
  Capability: require("../Assets/Images/capabilities.jpg"),
  Showcase: require("../Assets/Images/showcase.jpg"),

  ValueTransparency: require("../Assets/Images/OurCoreValue(Home)/Transparency.png"),
  ValueKnowledge: require("../Assets/Images/OurCoreValue(Home)/Knowledge.png"),
  ValueService: require("../Assets/Images/OurCoreValue(Home)/Service.png"),
  ValueValue: require("../Assets/Images/OurCoreValue(Home)/Value.png"),
  OurCoreValueImg1: require("../Assets/Images/OurCoreValue(Home)/OurCoreValues1.jpg"),
  OurCoreValueImg2: require("../Assets/Images/OurCoreValue(Home)/OurCoreValues2.jpg"),

  VideoImg: require("../Assets/Images/video-img.jpg"),
  VideoImg2: require("../Assets/Images/video-img2.jpg"),

  HowWorkArrow: require("../Assets/Images/HowWeWork/angle-icon.png"),
  HowWorkDiscuss: require("../Assets/Images/HowWeWork/discussion.png"),
  HowWorkIdea: require("../Assets/Images/HowWeWork/IdeaConcept.png"),
  HowWorkCode: require("../Assets/Images/HowWeWork/CodeAndTesting.png"),
  HowWorkLaunch: require("../Assets/Images/HowWeWork/launchAndGoLive.png"),

  ItWebDev: require("../Assets/Images/HomeItServices/ItWebDevelopmentIcon.png"),
  ItWebDesign: require("../Assets/Images/HomeItServices/ItWebDesignIcon.png"),
  ItMbDev: require("../Assets/Images/HomeItServices/ItMobileAppDesign.png"),
  ItDegital: require("../Assets/Images/HomeItServices/ItDigitalMarketing.png"),
  ItGraphic: require("../Assets/Images/HomeItServices/ItGraphicDesign.png"),
  ItTraining: require("../Assets/Images/HomeItServices/ItITTraining.png"),

  AboutBanner: require("../Assets/Images/AboutChallenge/about-banner.jpg"),
  AboutWhatWeCanDo: require("../Assets/Images/AboutChallenge/what-we-can-do.png"),
  AboutBecomeOurPartners: require("../Assets/Images/AboutChallenge/become-our-partners.png"),
  AboutNeedAHand: require("../Assets/Images/AboutChallenge/need-a-hand.png"),
  AboutWebMb: require("../Assets/Images/AboutChallenge/webAndMobiAppDevelopment.png"),
  AboutAward: require("../Assets/Images/AboutChallenge/AwardsAndDigitalInnovation.png"),
  AboutAPI: require("../Assets/Images/AboutChallenge/APIIntegretion.png"),
  AboutMb: require("../Assets/Images/AboutChallenge/mobileAndAppDesign.png"),
  AboutExprnc: require("../Assets/Images/AboutChallenge/Experience.png"),
  AboutCost: require("../Assets/Images/AboutChallenge/CostAndEffective.png"),
  AboutMeeting: require("../Assets/Images/AboutChallenge/meeting.png"),
  AboutClient: require("../Assets/Images/AboutChallenge/TrustByClients.png"),
  AboutLoyalty: require("../Assets/Images/AboutChallenge/loyaltyAndTrust.png"),
  AboutQuality: require("../Assets/Images/AboutChallenge/qualityAndMngmnt.png"),
  AboutMissionVision: require("../Assets/Images/AboutChallenge/MissionVisionImg.jpg"),
  AboutWhyChooseUs: require("../Assets/Images/AboutChallenge/whyChooseUs.jpg"),
  AboutBubble: require("../Assets/Images/AboutChallenge/bubble.png"),

  Dthrill_new: require("../Assets/Images/Dthrill_new.png"),
  AboutMb1: require("../Assets/Images/AboutChallenge/mb-1.png"),
  AboutMb2: require("../Assets/Images/AboutChallenge/mb-2.png"),

  TestimonialProfile1: require("../Assets/Images/Profile2.jpg"),
  TestimonialRight: require("../Assets/Images/Testimonials/Testimonials_right.jpg"),

  health: require("../Assets/Images/images-removebg-preview (1).png"),

  Marquee1: require("../Assets/Images/WebDesign/marquee-website.png"),
  WebDesignSolution: require("../Assets/Images/WebDesign/WebDesignSolutions.jpg"),
  DThrillWebDesign: require("../Assets/Images/WebDesign/DThrillWebDesign.jpg"),
  ResponsiveWebDesign: require("../Assets/Images/WebDesign/ResponsiveWebDesign.jpg"),
  DesignExperience: require("../Assets/Images/WebDesign/DesignExperience.jpg"),
  Brand: require("../Assets/Images/WebDesign/Brand.jpg"),

  WebDesignIcon1: require("../Assets/Images/WebDesign/icon1.png"),
  WebDesignIcon2: require("../Assets/Images/WebDesign/icon2.png"),

  Healthcare: require("../Assets/Images/WeSpecialize/healthcare.png"),
  Education: require("../Assets/Images/WeSpecialize/Education.png"),
  Payroll: require("../Assets/Images/WeSpecialize/Payroll.png"),
  Inventory: require("../Assets/Images/WeSpecialize/Inventory.png"),
  ECommerce: require("../Assets/Images/WeSpecialize/E-commerce.png"),
  Websites: require("../Assets/Images/WeSpecialize/Website.png"),
  HealthcareImg: require("../Assets/Images/WeSpecialize/healthcare-img.png"),
  EducationImg: require("../Assets/Images/WeSpecialize/Education-img.png"),
  PayrollImg: require("../Assets/Images/WeSpecialize/Payroll-img.png"),
  InventoryImg: require("../Assets/Images/WeSpecialize/Inventory-img.png"),
  ECommerceImg: require("../Assets/Images/WeSpecialize/E-commerce-img.png"),
  WebsitesImg: require("../Assets/Images/WeSpecialize/Website-img.png"),

  GDBanner: require("../Assets/Images/GraphicDesign/GraphicsDesignBanner.jpg"),
  GDPrintDesignGreen: require("../Assets/Images/GraphicDesign/print-design-green.jpg"),
  GDPrintDesignPurple: require("../Assets/Images/GraphicDesign/print-design-purple.jpg"),
  GDBrand1: require("../Assets/Images/GraphicDesign/Mockup1.jpg"),
  GDBrand2: require("../Assets/Images/GraphicDesign/Mockup2.jpg"),
  GDBrand3: require("../Assets/Images/GraphicDesign/Mockup3.jpg"),
  GDBrand4: require("../Assets/Images/GraphicDesign/Mockup4.jpg"),
  GDMan: require("../Assets/Images/GraphicDesign/home-cybersecurity-contact-bg-image.png"),
  GDVisitingCard: require("../Assets/Images/GraphicDesign/visiting-card.png"),
  GDBrochure: require("../Assets/Images/GraphicDesign/brochure.png"),
  GDCatalogue: require("../Assets/Images/GraphicDesign/catalogue.png"),
  GDCorporateIdentity: require("../Assets/Images/GraphicDesign/brand-identity.png"),
  GDInvititionCard: require("../Assets/Images/GraphicDesign/invitation.png"),
  GDAdvertisement: require("../Assets/Images/GraphicDesign/advertising.png"),
  GDNewsletter: require("../Assets/Images/GraphicDesign/information.png"),
  GDGreetings: require("../Assets/Images/GraphicDesign/greeting-card.png"),
  GDBook: require("../Assets/Images/GraphicDesign/book.png"),

  DMBanner: require("../Assets/Images/DigitalMarketing/dg1.png"),
  DMImg1: require("../Assets/Images/DigitalMarketing/dg1.png"),
  DMImgWorking: require("../Assets/Images/DigitalMarketing/working-progress.png"),
  DMImgAbout: require("../Assets/Images/DigitalMarketing/dm-about.png"),
  registration_1: require("../Assets/Images/Registration/registration_1.jpg"),
  global: require("../Assets/Images/global.png"),
  dreameEstate: require("../Assets/Images/OurPartners/DreamEstateLogo.png"),
  DMPartnership: require("../Assets/Images/DigitalMarketing/partnership.png"),
  DMInnovation: require("../Assets/Images/DigitalMarketing/innovation.png"),
  DMStratergy: require("../Assets/Images/DigitalMarketing/Market-Strategy.png"),
  DMFunction: require("../Assets/Images/DigitalMarketing/support-function.png"),
  DMResearch: require("../Assets/Images/DigitalMarketing/Marketing-research.png"),
  DMLead: require("../Assets/Images/DigitalMarketing/lead.png"),
  DMHappyCustomer: require("../Assets/Images/DigitalMarketing/HappyCustomer.png"),
  DMExpertAdvisor: require("../Assets/Images/DigitalMarketing/ExpertAdvisor.png"),
  DMProjectCompleted: require("../Assets/Images/DigitalMarketing/ProjectCompleted.png"),
  DMAudit: require("../Assets/Images/DigitalMarketing/Audit.jpg"),
  DMSEOStrategy: require("../Assets/Images/DigitalMarketing/SEOStrategy.jpg"),
  DMOnSiteSEO: require("../Assets/Images/DigitalMarketing/On-siteSEO.jpg"),

  sliderHome: require("../Assets/Images/sliderImg.jpg"),
  footer_bg: require("../Assets/Images/Footer_bg.jpg"),

  ITTrainEdu: require("../Assets/Images/ITTraining/education-hero.jpg"),
  ITTrainCoaching: require("../Assets/Images/ITTraining/coaching.png"),

  slider1: require("../Assets/Images/slider-1.jpg"),
  slider2: require("../Assets/Images/slider-2.jpg"),
  slider3: require("../Assets/Images/slider-3.jpg"),

  CompititionSmall: require("../Assets/Images/CompetitionSmallImg.jpg"),
  CompititionLarge: require("../Assets/Images/CompetitionLarge.jpg"),

  WebsiteDev: require("../Assets/Images/WebDevelopement/WebsiteDevelopment.jpg"),
  UserExp: require("../Assets/Images/WebDevelopement/UserExperience.jpg"),
  TechExpertise: require("../Assets/Images/WebDevelopement/TechnicalExpertise.jpg"),
  MobileFriendly: require("../Assets/Images/WebDevelopement/MobileFriendly.jpg"),
  WebDevTrust: require("../Assets/Images/WebDevelopement/trust.png"),
  WebDevProd: require("../Assets/Images/WebDevelopement/prod-eng.png"),
  WebDevSecure: require("../Assets/Images/WebDevelopement/secure.png"),
  WebDevReach: require("../Assets/Images/WebDevelopement/reach-cust.png"),

  ServicesWebDesign: require("../Assets/Images/Services/website-design.avif"),
  ServicesDM: require("../Assets/Images/Services/DigitalMarketing.jpg"),
  ServicesGD: require("../Assets/Images/Services/graphics-Design.avif"),
  ServicesITTraining: require("../Assets/Images/Services/it-traing.avif"),

  BlogHowMany: require("../Assets/Images/Blogs/OuterImg/digitalPM.jpeg"),
  BlogOuterDM: require("../Assets/Images/Blogs/OuterImg/DigitalMarketing.jpg"),
  BlogOuterWD: require("../Assets/Images/Blogs/OuterImg/WebDesign.jpg"),
  BlogOuterAffilate1: require("../Assets/Images/Blogs/OuterImg/AffiliateMarketing01.jpg"),
  BlogOuterAffilate2: require("../Assets/Images/Blogs/OuterImg/AffiliateMarketing02.jpg"),
  BlogOuterAffilate3: require("../Assets/Images/Blogs/OuterImg/AffiliateMarketing03.jpg"),
  BlogInnerDM: require("../Assets/Images/Blogs/InnerImg/DigitalMarketing.jpg"),
  BlogInnerWD: require("../Assets/Images/Blogs/InnerImg/WebDesign.jpg"),
  BlogInnerAffilate1: require("../Assets/Images/Blogs/InnerImg/AffiliateMarketing01.jpg"),
  BlogInnerAffilate2: require("../Assets/Images/Blogs/InnerImg/AffiliateMarketing02.jpg"),
  BlogInnerAffilate3: require("../Assets/Images/Blogs/InnerImg/AffiliateMarketing03.jpg"),

  ProductsSchoolImg: require("../Assets/Images/Product/SchoolManagementImg.jpg"),
  ProductsSchoolIcon: require("../Assets/Images/Product/SchoolManagementIcon.png"),
  ProductsSpaImg: require("../Assets/Images/Product/SPAManagementImg.jpg"),
  ProductsSpaIcon: require("../Assets/Images/Product//SPAManagementIcon.png"),
  ProductsHospitalImg: require("../Assets/Images/Product/HospitalManagementImg.jpg"),
  ProductsHospitalIcon: require("../Assets/Images/Product/HospitalManagementIcon.png"),
  ProductsInventoryImg: require("../Assets/Images/Product/InventoryManagementImg.jpg"),
  ProductsInventoryIcon: require("../Assets/Images/Product/InventoryManagementIcon.png"),
  ProductsPayrollImg: require("../Assets/Images/Product/PayrollManagementImg.jpg"),
  ProductsPayrollIcon: require("../Assets/Images/Product/PayrollManagementIcon.png"),

  MobileAppBanner: require("../Assets/Images/MobileDev/MobileAppMainBanner.jpg"),
  MobileAppSingle: require("../Assets/Images/MobileDev/single-mb-1.png"),
  MobileInnoCard: require("../Assets/Images/MobileDev/icon.png"),
  MobileParallax: require("../Assets/Images/MobileDev/mb-parallax.jpg"),
  MobileIOS: require("../Assets/Images/MobileDev/apple-mb.png"),
  MobileAndroid: require("../Assets/Images/MobileDev/mobiles2.png"),
  MobileIdea: require("../Assets/Images/MobileDev/IdeaAndPlanning.jpg"),
  MobileWireframing: require("../Assets/Images/MobileDev/WireframingAndDesign.jpg"),
  MobileDevelopment: require("../Assets/Images/MobileDev/Development.jpg"),
  MobileTesting: require("../Assets/Images/MobileDev/Testing.jpg"),
  MobileDeployment: require("../Assets/Images/MobileDev/Deployment.jpg"),
  MobileMaintenance: require("../Assets/Images/MobileDev/MaintenanceAndUpdates.jpg"),

  // Testimonials
  Aashish: require("../Assets/Images/Testimonials/Aashish-Dhole.jpeg"),
  Sunil: require("../Assets/Images/Testimonials/Sunil-sir.jpg"),
  Sandeep: require("../Assets/Images/Testimonials/Sandeep-sir.jpg"),

  // Footer Our Partner
  AbhitaFilm: require("../Assets/Images/OurPartners/AbhitaFilm.png"),
  Abuzz: require("../Assets/Images/OurPartners/Abuzz.png"),
  Rajarshi: require("../Assets/Images/OurPartners/RajshreeSahuFoundation.png"),
  Abhitaland: require("../Assets/Images/OurPartners//abhitaland.png"),
};

export { IMAGES };
