import React, { useEffect, useState } from "react";
import {
  MdKeyboardArrowRight,
  MdOutlineKeyboardArrowDown,
  MdOutlineLocationOn,
  MdOutlineMarkEmailRead,
  MdOutlinePhoneInTalk,
  MdSearch,
} from "react-icons/md";
import { HiMenu } from "react-icons/hi";
import { RxInstagramLogo } from "react-icons/rx";
import { CiLinkedin, CiTwitter } from "react-icons/ci";
import { RiFacebookCircleLine } from "react-icons/ri";
import { ImPinterest2 } from "react-icons/im";
import { TbArrowBigRight } from "react-icons/tb";
import { IMAGES } from "../Utils";
import "../Assets/Style/Header.css";
import { Link, useNavigate } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

const Header = () => {
  const [mbViewShow, setmbViewShow] = useState(false);

  const handleClose = () => {
    setmbViewShow(false);
  };
  const handleShow = () => {
    setmbViewShow(true);
  };

  const [show, setShow] = useState(false);
  const controlNavbar = () => {
    if (window.scrollY > 250) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  const jumpToRegistration = () => {
    navigate("/registration");
    setmbViewShow(false);
  };

  const [isHoveringWebDesign, setIsHoveringWebDesign] = useState(true);
  const [isHoveringWebDev, setIsHoveringWebDev] = useState(false);
  const [isHoveringGraphics, setIsHoveringGraphics] = useState(false);
  const [isHoveringDigital, setIsHoveringDigital] = useState(false);
  const [isHoveringIT, setIsHoveringIT] = useState(false);
  const [isHoveringMobile, setIsHoveringMobile] = useState(false);

  const handleMouseOverWebDesign = () => {
    setIsHoveringWebDesign(true);
    setIsHoveringWebDev(false);
    setIsHoveringGraphics(false);
    setIsHoveringDigital(false);
    setIsHoveringIT(false);
    setIsHoveringMobile(false);
  };

  const handleMouseOverWebDev = () => {
    setIsHoveringWebDev(true);
    setIsHoveringGraphics(false);
    setIsHoveringDigital(false);
    setIsHoveringWebDesign(false);
    setIsHoveringIT(false);
    setIsHoveringMobile(false);
  };

  const handleMouseOverDigital = () => {
    setIsHoveringDigital(true);
    setIsHoveringWebDev(false);
    setIsHoveringGraphics(false);
    setIsHoveringWebDesign(false);
    setIsHoveringIT(false);
    setIsHoveringMobile(false);
  };

  const handleMouseOverGraphics = () => {
    setIsHoveringGraphics(true);
    setIsHoveringWebDev(false);
    setIsHoveringDigital(false);
    setIsHoveringWebDesign(false);
    setIsHoveringIT(false);
    setIsHoveringMobile(false);
  };

  const handleMouseOverMobile = () => {
    setIsHoveringMobile(true);
    setIsHoveringIT(false);
    setIsHoveringGraphics(false);
    setIsHoveringWebDev(false);
    setIsHoveringDigital(false);
    setIsHoveringWebDesign(false);
  };

  const handleMouseOverIT = () => {
    setIsHoveringIT(true);
    setIsHoveringGraphics(false);
    setIsHoveringWebDev(false);
    setIsHoveringDigital(false);
    setIsHoveringWebDesign(false);
    setIsHoveringMobile(false);
  };

  // const [isHoveringMedia, setIsHoveringMedia] = useState(true);
  const [isHoveringBlog, setIsHoveringBlog] = useState(true);
  const [isHoveringByIndustry, setIsHoveringByIndustry] = useState(false);

  // const handleMouseOverMedia = () => {
  //   setIsHoveringMedia(true);
  //   setIsHoveringBlog(false);
  //   setIsHoveringByIndustry(false);
  // };
  const handleMouseOverBlog = () => {
    setIsHoveringBlog(true);
    // setIsHoveringMedia(false);
    setIsHoveringByIndustry(false);
  };
  const handleMouseOverByIndustry = () => {
    setIsHoveringByIndustry(true);
    // setIsHoveringMedia(false);
    setIsHoveringBlog(false);
  };

  const showInMapClicked = () => {
    window.open("https://maps.google.com?q=" + 18.58578 + "," + 73.81608);
  };

  const phoneNumber = "+918668747836";
  function handleDialClick() {
    window.open(`tel:${phoneNumber}`);
  }

  return (
    <>
      <div className="fixed">
        <div className="header-height">
          <div className="fixed-header">
            <div className="header">
              <div className={`top-header ${show && "hidden"}`}>
                <div className="top-bar-spacing">
                  <div className="topbar-section">
                    <div className="row">
                      <div className="col-lg-4 col-md-12 col-sm-12 top-left-mb d-flex">
                        <div className="top-bar-left">
                          <a
                            href=""
                            className="top-header-location"
                            onClick={showInMapClicked}
                          >
                            <MdOutlineLocationOn
                              size={25}
                              color="white"
                              className="mx-1"
                            />
                            <p>New Sangvi, Pune</p>
                          </a>
                          <a className="top-header-email">
                            <MdOutlineMarkEmailRead
                              size={25}
                              color="white"
                              className="mx-1"
                            />
                            <p>info@dthrill.com</p>
                          </a>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12 col-sm-12 d-flex">
                        {/* <div className="top-bar-center">
                          <input />
                          <MdSearch size={20} className="search" />
                        </div> */}
                      </div>
                      <div className="col-lg-4 col-md-12 col-sm-12 d-flex">
                        <div className="top-bar-right">
                          <a
                            href="tel://+91 8668 747 836"
                            className="header-mb"
                          >
                            <MdOutlinePhoneInTalk
                              size={25}
                              color="white"
                              className="mx-1 header-mb"
                            />
                            <p>+91 8668 747 836</p>
                          </a>
                          {/* <div className="social-icon-section">
                            <div>
                              <TiSocialFacebook
                                size={25}
                                className="social-icons"
                              />
                            </div>
                            <div>
                              <RxInstagramLogo
                                size={25}
                                className="social-icons"
                              />
                            </div>
                            <div>
                              <CiTwitter size={25} className="social-icons" />
                            </div>
                            <div>
                              <CiLinkedin size={25} className="social-icons" />
                            </div>
                            <div>
                              <ImPinterest2
                                size={21}
                                className="social-icons"
                              />
                            </div>
                          </div> */}
                          <div className="social-icon-section">
                            <ul>
                              <li>
                                <a
                                  href="https://www.facebook.com/dthrillsoftwaresolutions"
                                  target="_about"
                                >
                                  <RiFacebookCircleLine
                                    size={25}
                                    className="social-icons facebook"
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.instagram.com/developersthrill/"
                                  target="_about"
                                >
                                  <RxInstagramLogo
                                    size={25}
                                    className="social-icons insta"
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://twitter.com/SoftwareDthrill"
                                  target="_about"
                                >
                                  <CiTwitter
                                    size={25}
                                    className="social-icons twitter"
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.linkedin.com/company/37807420/admin/"
                                  target="_about"
                                >
                                  <CiLinkedin
                                    size={25}
                                    className="social-icons linkedin"
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://in.pinterest.com/dthrillsoftwaresolutions/"
                                  target="_about"
                                >
                                  <ImPinterest2
                                    size={21}
                                    className="social-icons pintrest"
                                  />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-header">
                <div className="main-bar-spacing">
                  <div className="row">
                    <div className="col-lg-2 col-md-12 col-sm-12 logo-menuIcon">
                      <div className="">
                        <Link to="/" className="header-link">
                          <img
                            src={IMAGES.Dthrill_new}
                            alt="logo"
                            className="logo"
                          />
                        </Link>
                      </div>
                      <div className="menu-icon">
                        <HiMenu size={35} onClick={handleShow} />
                      </div>
                    </div>
                    <div className="col-lg-8 menu">
                      <div className="menu-content">
                        <Link to="/" className="header-link">
                          Home
                        </Link>
                        <Link to="/aboutus" className="header-link">
                          About
                        </Link>
                        {/* <a href="/aboutus">About</a> */}
                        <div className="services-drp">
                          <Link to="/services" className="header-link">
                            Services{" "}
                            <MdOutlineKeyboardArrowDown
                              size={20}
                              className="down-arrow"
                            />
                          </Link>
                          <div className="row mega-drop-wrap">
                            <div className="col-lg-3">
                              <div className="mega-menu-list">
                                <Link
                                  to="/webdesign"
                                  className=""
                                  onMouseOver={handleMouseOverWebDesign}
                                >
                                  <span className="web-design-span">
                                    <TbArrowBigRight
                                      size={20}
                                      className="mega-menu-arrow"
                                    />
                                    <p className="web-design-p">Web Design</p>
                                  </span>
                                </Link>
                                <Link
                                  to="/webdevelopment"
                                  className=""
                                  onMouseOver={handleMouseOverWebDev}
                                >
                                  <span className="">
                                    <TbArrowBigRight
                                      size={20}
                                      className="mega-menu-arrow"
                                    />
                                    <p className="">Web Development</p>
                                  </span>
                                </Link>
                                <Link
                                  to="/digitalmarketing"
                                  className=""
                                  onMouseOver={handleMouseOverDigital}
                                >
                                  <span className="">
                                    <TbArrowBigRight
                                      size={20}
                                      className="mega-menu-arrow"
                                    />
                                    <p className="">Digital Marketing</p>
                                  </span>
                                </Link>
                                <Link
                                  to="/graphicdesign"
                                  className=""
                                  onMouseOver={handleMouseOverGraphics}
                                >
                                  <span className="">
                                    <TbArrowBigRight
                                      size={20}
                                      className="mega-menu-arrow"
                                    />
                                    <p className="">Graphics Design</p>
                                  </span>
                                </Link>
                                <Link
                                  to="/mobileappdevelopment"
                                  className=""
                                  onMouseOver={handleMouseOverMobile}
                                >
                                  <span className="">
                                    <TbArrowBigRight
                                      size={20}
                                      className="mega-menu-arrow"
                                    />
                                    <p className="">
                                      Mobile App
                                      <br /> Development
                                    </p>
                                  </span>
                                </Link>
                                <Link
                                  to="/ittraining"
                                  className=""
                                  onMouseOver={handleMouseOverIT}
                                >
                                  <span className="">
                                    <TbArrowBigRight
                                      size={20}
                                      className="mega-menu-arrow"
                                    />
                                    <p className="">IT Corporate Training</p>
                                  </span>
                                </Link>
                              </div>
                            </div>
                            <div className="col-lg-9">
                              <div className="mega-menu-content-section">
                                {isHoveringWebDesign ? (
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="mega-menu-info-content">
                                        <div className="mega-menu-info-content-topics">
                                          <h5>Web Design</h5>
                                        </div>
                                        <p>
                                          Our website design showcase
                                          illustrates our versatility and
                                          commitment. We create affordable
                                          tailor-made design solutions for
                                          businesses in a diverse range of
                                          industries.
                                        </p>
                                        <div className="mega-menu-info-content-topics">
                                          <h5>WebSite Type</h5>
                                          <div className="website-type">
                                            <p>Static | Dynamic | E-Commerce</p>
                                          </div>
                                        </div>
                                        <div className="mult-tech-wrapper">
                                          <h5>Technolgies</h5>
                                          <ul className="">
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              HTML, CSS, Bootstrap
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Tailwind
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              JavaScript
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              JQuery
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              ReactJS
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              React Native
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="mega-menu-blog-content">
                                        <a href="#">
                                          <img
                                            src={IMAGES.WebDesignHomeCard}
                                            alt=""
                                          />

                                          <span className="mega-menu-blog-details">
                                            Web Design | Blog
                                          </span>
                                          <strong>Web Design</strong>
                                          <p>
                                            Our website design showcase
                                            illustrates our versatility and
                                            commitment. We create affordable
                                            tailor-made design solutions for
                                            businesses in a diverse range of
                                            industries.
                                          </p>
                                          <strong
                                            className="btn_megaBlockReadMore animated-button1"
                                            onClick={() =>
                                              navigate("/webdesign")
                                            }
                                          >
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            Read more
                                          </strong>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {isHoveringWebDev ? (
                                  <div className="row rowWebDev">
                                    <div className="col-lg-6">
                                      <div className="mega-menu-info-content">
                                        <div className="mega-menu-info-content-topics">
                                          <h5>Web Development</h5>
                                        </div>
                                        <p>
                                          It’s important to have your site
                                          performing well, and also be
                                          accessible to users 24/7 (without
                                          technical difficulties).
                                        </p>
                                        {/* <div className="mega-menu-info-content-topics">
                                        <h5>WebSite Type</h5>
                                        <div className="website-type">
                                          <p>Static Website</p>
                                          <p>Dynamic Website</p>
                                          <p>E-Commerce Website</p>
                                        </div>
                                      </div> */}
                                        <div className="mult-tech-wrapper">
                                          <h5>Technolgies</h5>
                                          <ul className="mult-tech">
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              ReactJS
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              React Native
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              NodeJs
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              ExpressJs
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              .Net Core
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Php
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Other
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="mega-menu-blog-content">
                                        <a href="#">
                                          <img
                                            src={IMAGES.WebDevHomeCard}
                                            alt=""
                                          />

                                          <span className="mega-menu-blog-details">
                                            Web Development | Blog
                                          </span>
                                          <strong>Web Development</strong>
                                          <p>
                                            DThrill is flexible and will mould
                                            the production processes in a way
                                            that will compliment your timeline
                                            and budget requirements.
                                          </p>
                                          <button
                                            className="btn_megaBlockReadMore animated-button1"
                                            onClick={() =>
                                              navigate("/webdevelopment")
                                            }
                                          >
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            Read more
                                          </button>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {isHoveringDigital ? (
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="mega-menu-info-content">
                                        <div className="mega-menu-info-content-topics">
                                          <h5>Digital Marketing</h5>
                                        </div>
                                        <p>
                                          The most relavant websites for users
                                          search query should be the top ranking
                                          search. You should focus on the
                                          strategy on attracting people who will
                                          engage and convert.
                                        </p>
                                        <div className="mult-tech-wrapper">
                                          <h5>Services</h5>
                                          <ul className="mult-tech">
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Social Media Marketing (SMM)
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Search Engine Optimization (SEO)
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Google Campaign
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Google My Business
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Google Search Console
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Content Marketing
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Google Analytics
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Other
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="mega-menu-blog-content">
                                        <a href="#">
                                          <img
                                            src={IMAGES.DigitalHomeCard}
                                            alt=""
                                          />

                                          <span className="mega-menu-blog-details">
                                            Digital Marketing | Blog
                                          </span>
                                          <strong>Digital Marketing</strong>
                                          <p>
                                            The most relavant websites for users
                                            search query should be the top
                                            ranking search.
                                          </p>
                                          <button
                                            className="btn_megaBlockReadMore animated-button1"
                                            onClick={() =>
                                              navigate("/digitalmarketing")
                                            }
                                          >
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            Read more
                                          </button>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {isHoveringGraphics ? (
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="mega-menu-info-content">
                                        <div className="mega-menu-info-content-topics">
                                          <h5>Graphics Design</h5>
                                        </div>
                                        <p>
                                          Companies use graphic design service
                                          to promote and sell products as well
                                          as to make services more pleasing
                                          manner. Also it is very showing
                                          information into graphics for a
                                          visiter/user to understanding the
                                          product or service.
                                        </p>
                                        <div className="mult-tech-wrapper">
                                          <h5>Tools</h5>
                                          <ul className="mult-tech">
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Adobe Photoshop
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Adobe Illustrator
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Adobe Indesign
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Adobe Premiere
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Filmora (Video Editing)
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              CorelDRAW
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Canva, Snapa
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Other
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="mega-menu-blog-content">
                                        <a href="#">
                                          <img
                                            src={IMAGES.GraphicsHomeCard}
                                            alt=""
                                          />

                                          <span className="mega-menu-blog-details">
                                            Graphics Design | Blog
                                          </span>
                                          <strong>Graphics Design</strong>
                                          <p>
                                            Companies use graphic design service
                                            to promote and sell products as well
                                            as to make services more pleasing
                                            manner.
                                          </p>
                                          <button
                                            className="btn_megaBlockReadMore animated-button1"
                                            onClick={() =>
                                              navigate("/graphicdesign")
                                            }
                                          >
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            Read more
                                          </button>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {isHoveringMobile ? (
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="mega-menu-info-content">
                                        <div className="mega-menu-info-content-topics">
                                          <h5>Mobile App Development</h5>
                                        </div>
                                        <p>
                                          Mobile app development is the process
                                          of creating software applications
                                          specifically designed to run on mobile
                                          devices, such as smartphones and
                                          tablets.
                                        </p>
                                        <div className="mult-tech-wrapper">
                                          <h5>Technology</h5>
                                          <ul className="mult-tech">
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              React Native
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Android
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Flutter
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Hybrid
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="mega-menu-blog-content">
                                        <a href="#">
                                          <img
                                            src={
                                              IMAGES.MbAppDevelopmentHomeCard
                                            }
                                            alt=""
                                          />

                                          <span className="mega-menu-blog-details">
                                            Mobile App Development | Blog
                                          </span>
                                          <strong>
                                            Mobile App Development
                                          </strong>
                                          <p>
                                            We assist our clients in
                                            establishing their brands and
                                            attracting a larger audience by
                                            developing mobile applications.
                                          </p>
                                          <button
                                            className="btn_megaBlockReadMore animated-button1"
                                            onClick={() =>
                                              navigate("/mobileappdevelopment")
                                            }
                                          >
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            Read more
                                          </button>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {isHoveringIT ? (
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="mega-menu-info-content">
                                        <div className="mega-menu-info-content-topics">
                                          <h5>IT Corporate Training</h5>
                                        </div>
                                        <p>
                                          Take online web design courses to
                                          build and advance your design skills.
                                          Respondent Web Design requires an
                                          appreciation of the web content
                                          accessing technologies and networks
                                          and of consumer usability needs.
                                        </p>
                                        <div className="mult-tech-wrapper">
                                          <h5>Training</h5>
                                          <ul className="mult-tech">
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Web Design Training
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Web Development Training
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Digital Marketing Training
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Graphics Design Training
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              HR / Recruitment Training
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Implant Training
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Internship
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Other
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="mega-menu-blog-content">
                                        <a href="#">
                                          <img
                                            src={IMAGES.ServicesITTraining}
                                            alt=""
                                          />

                                          <span className="mega-menu-blog-details">
                                            IT Corporate Training | Blog
                                          </span>
                                          <strong>IT Corporate Training</strong>
                                          <p>
                                            Take online web design courses to
                                            build and advance your design
                                            skills.
                                          </p>
                                          <button
                                            className="btn_megaBlockReadMore animated-button1"
                                            onClick={() => navigate("#")}
                                          >
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            Read more
                                          </button>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <Link to="/products" className="header-link">
                          Products
                        </Link>
                        <Link to="/ittraining" className="header-link">
                          IT Training
                        </Link>
                        <Link to="/blog" className="header-link insights-drp">
                          Insights{" "}
                          <MdOutlineKeyboardArrowDown
                            size={20}
                            className="down-arrow"
                          />
                          <div className="row mega-drop-wrap-insight">
                            <div className="col-lg-3">
                              <div className="mega-menu-list">
                                {/* <Link
                                  to="/gallary"
                                  className=""
                                  onMouseOver={handleMouseOverMedia}
                                  // onMouseOver={handleInsight}
                                >
                                  <span className="">
                                    <TbArrowBigRight
                                      size={20}
                                      className="mega-menu-arrow"
                                    />
                                    <p className="web-design-p">Media</p>
                                  </span>
                                </Link> */}
                                <Link
                                  to="#"
                                  className=""
                                  onMouseOver={handleMouseOverByIndustry}
                                >
                                  <span className="">
                                    <TbArrowBigRight
                                      size={20}
                                      className="mega-menu-arrow"
                                    />
                                    <p className="">By Industry</p>
                                  </span>
                                </Link>
                                <Link
                                  to="/blog"
                                  className=""
                                  onMouseOver={handleMouseOverBlog}
                                >
                                  <span className="">
                                    <TbArrowBigRight
                                      size={20}
                                      className="mega-menu-arrow"
                                    />
                                    <p className="">Blog</p>
                                  </span>
                                </Link>
                                <Link
                                  to="#"
                                  className=""
                                  onMouseOver={handleMouseOverDigital}
                                >
                                  <span className="">
                                    <TbArrowBigRight
                                      size={20}
                                      className="mega-menu-arrow"
                                    />
                                    <p className="">Careers</p>
                                  </span>
                                </Link>
                              </div>
                            </div>
                            <div className="col-lg-9">
                              <div className="mega-menu-content-section">
                                {/* {isHoveringMedia ? (
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="mega-menu-info-content">
                                        <div className="mega-menu-info-content-topics">
                                          <h5>Media</h5>
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-3">
                                            <div className="mega-menu-img-wrapper">
                                              <img
                                                src={IMAGES.Capability}
                                                alt=""
                                                className="mega-menu-img"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-3">
                                            <div className="mega-menu-img-wrapper">
                                              <img
                                                src={IMAGES.Capability}
                                                alt=""
                                                className="mega-menu-img"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-3">
                                            <div className="mega-menu-img-wrapper">
                                              <img
                                                src={IMAGES.Capability}
                                                alt=""
                                                className="mega-menu-img"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-3">
                                            <div className="mega-menu-img-wrapper">
                                              <img
                                                src={IMAGES.Capability}
                                                alt=""
                                                className="mega-menu-img"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-3">
                                            <div className="mega-menu-img-wrapper">
                                              <img
                                                src={IMAGES.Capability}
                                                alt=""
                                                className="mega-menu-img"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-3">
                                            <div className="mega-menu-img-wrapper">
                                              <img
                                                src={IMAGES.Capability}
                                                alt=""
                                                className="mega-menu-img"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-3">
                                            <div className="mega-menu-img-wrapper">
                                              <img
                                                src={IMAGES.Capability}
                                                alt=""
                                                className="mega-menu-img"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-3">
                                            <div className="mega-menu-img-wrapper">
                                              <img
                                                src={IMAGES.Capability}
                                                alt=""
                                                className="mega-menu-img"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-3">
                                            <div className="mega-menu-img-wrapper">
                                              <img
                                                src={IMAGES.Capability}
                                                alt=""
                                                className="mega-menu-img"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-3">
                                            <div className="mega-menu-img-wrapper">
                                              <img
                                                src={IMAGES.Capability}
                                                alt=""
                                                className="mega-menu-img"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-3">
                                            <div className="mega-menu-img-wrapper">
                                              <img
                                                src={IMAGES.Capability}
                                                alt=""
                                                className="mega-menu-img"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-3">
                                            <div className="mega-menu-img-wrapper">
                                              <img
                                                src={IMAGES.Capability}
                                                alt=""
                                                className="mega-menu-img"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                  </div>
                                ) : (
                                  ""
                                )} */}

                                {isHoveringByIndustry ? (
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="mega-menu-info-content">
                                        <div className="mega-menu-info-content-topics">
                                          <h5>By Industry</h5>
                                        </div>
                                        <p>
                                          Our technology is very scalable. Our
                                          software can accommodate enormous
                                          numbers of clients. It's a marvelous
                                          opportunity. We'll keep developing
                                          products.
                                        </p>
                                        <div className="mult-tech-wrapper">
                                          <h5>Technolgies</h5>
                                          <ul className="mult-tech">
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Healthcare
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Education
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Payroll
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Inventory
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              E-Commerce
                                            </li>
                                            <li>
                                              <MdKeyboardArrowRight size={20} />
                                              Websites
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="mega-menu-blog-content">
                                        <a href="#">
                                          <img src={IMAGES.ByIndustry} alt="" />

                                          <span className="mega-menu-blog-details">
                                            By Industry | Blog
                                          </span>
                                          <strong>By Industry</strong>
                                          <p>
                                            DThrill is flexible and will mould
                                            the production processes in a way
                                            that will compliment your timeline
                                            and budget requirements.
                                          </p>
                                          <button className="btn_megaBlockReadMore animated-button1">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            Read more
                                          </button>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {isHoveringBlog ? (
                                  <div className="row">
                                    <div className="col-lg-6 ">
                                      <div className="header-blog-section">
                                        <div className="header-menu-blog-wrapper">
                                          <img
                                            src={IMAGES.BlogOuterDM}
                                            alt=""
                                          />
                                          <div className="menu-blog-name-service">
                                            <h6>Mitali Narkhede</h6>
                                            <h6>Web Design</h6>
                                          </div>
                                          <div className="menu-blog-header">
                                            <h3>
                                              Digital marketing is essential for
                                              small companies
                                            </h3>
                                          </div>
                                          <div className="menu-blog-text">
                                            <p>
                                              You're a company owner? Super!
                                              wait, wait. Have you ever been
                                              thinking where you're on the
                                              market? ...
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="header-blog-section">
                                        <div className="header-menu-blog-wrapper">
                                          <img
                                            src={IMAGES.BlogOuterWD}
                                            alt=""
                                          />
                                          <div className="menu-blog-name-service">
                                            <h6>Mitali Narkhede</h6>
                                            <h6>Web Design</h6>
                                          </div>
                                          <div className="menu-blog-header">
                                            <h3>What Is Web Design?</h3>
                                          </div>
                                          <div className="menu-blog-text">
                                            <p>
                                              Web Design is the process of
                                              making websites and mobile
                                              applications appealing to the
                                              target audience. The web designer
                                              needs to be good at ...
                                            </p>
                                          </div>
                                        </div>
                                        <div className="menu-blog-read-btn">
                                          <button
                                            className="animated-button1"
                                            onClick={() => navigate("/blog")}
                                          >
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            Read More
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>
                        <Link to="/contact" className="header-link">
                          Contact
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-2 menu-right">
                      <div>
                        <button
                          onClick={jumpToRegistration}
                          className="reg-btn animated-button1"
                        >
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          Registration
                        </button>
                      </div>
                      {/* <div>
                        <a
                          href="https://wa.link/q7jivv"
                          target="_about"
                          className="live-connect-btn animated-button1"
                        >
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          Live Connect
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Offcanvas */}

      <Offcanvas show={mbViewShow} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <img src={IMAGES.Dthrill_new} alt="logo" className="logo" />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mb-social-icon-section">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/dthrillsoftwaresolutions"
                  target="_about"
                >
                  <RiFacebookCircleLine
                    size={25}
                    className="social-icons facebook"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/developersthrill/"
                  target="_about"
                >
                  <RxInstagramLogo size={25} className="social-icons insta" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/SoftwareDthrill" target="_about">
                  <CiTwitter size={25} className="social-icons twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/37807420/admin/"
                  target="_about"
                >
                  <CiLinkedin size={25} className="social-icons linkedin" />
                </a>
              </li>
              <li>
                <a
                  href="https://in.pinterest.com/dthrillsoftwaresolutions/"
                  target="_about"
                >
                  <ImPinterest2 size={21} className="social-icons pintrest" />
                </a>
              </li>
            </ul>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <button
                onClick={jumpToRegistration}
                className="reg-btn animated-button1"
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Registration
              </button>
            </div>
            {/* <div className="mx-2">
              <a
                href="https://wa.link/q7jivv"
                target="_about"
                className="live-connect-btn "
              >
                Live Connect
              </a>
            </div> */}
          </div>

          {/* <div className="mb-search-bar">
            <input />
            <MdSearch size={20} className="mb-search-icon" />
          </div> */}

          <div className="mb-menu">
            <ul className="menu-content">
              <Link to="/" className="mb-header-link" onClick={handleClose}>
                Home
              </Link>
              <Link
                to="/aboutus"
                className="mb-header-link"
                onClick={handleClose}
              >
                About
              </Link>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Services <MdOutlineKeyboardArrowDown size={20} />
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <Link to="/webdesign" onClick={handleClose}>
                        Web Design
                      </Link>
                    </p>
                    <p>
                      <Link to="/webdevelopment" onClick={handleClose}>
                        Web development
                      </Link>
                    </p>
                    <p>
                      <Link to="/digitalmarketing" onClick={handleClose}>
                        Digital Marketing
                      </Link>
                    </p>
                    <p>
                      <Link to="/graphicdesign" onClick={handleClose}>
                        Graphics Design
                      </Link>
                    </p>
                    <p>
                      <Link to="/mobileappdevelopment" onClick={handleClose}>
                        Mobile App Dev
                      </Link>
                    </p>
                    <p>
                      <Link to="/ittraining" onClick={handleClose}>
                        IT Corporate Training
                      </Link>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Link
                to="/products"
                className="mb-header-link"
                onClick={handleClose}
              >
                Products
              </Link>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Insights <MdOutlineKeyboardArrowDown size={20} />
                  </Accordion.Header>
                  <Accordion.Body>
                    {/* <p>
                      <Link to="/webdesign">Media</Link>
                    </p> */}
                    <p>
                      <Link to="#" onClick={handleClose}>
                        By Industry
                      </Link>
                    </p>
                    <p>
                      <Link to="/blog" onClick={handleClose}>
                        Blog
                      </Link>
                    </p>
                    <p>
                      <Link to="#" onClick={handleClose}>
                        Careers
                      </Link>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Link
                to="/contact"
                onClick={handleClose}
                className="mb-header-link"
              >
                Contact
              </Link>
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
