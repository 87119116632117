import React from "react";
import "../Assets/Style/Careers.css";
import { MdArrowForward } from "react-icons/md";
import { AnimationOnScroll } from "react-animation-on-scroll";

function Careers() {
  return (
    <>
      <div className="Careers">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 careersLeft">
              <div class="subtitle">Careers </div>
              <AnimationOnScroll animateIn="animate__zoomIn">
              <h3 className="title">
                Join our team to <br />
                create the best digital
                <br /> solutions.
              </h3>
              </AnimationOnScroll>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 careersRight">
              <div>
                <p>
                  <strong>We’re a team of creatives</strong> who are excited
                  about unique ideas and help fin-tech companies to create
                  amazing identity by crafting top-notch UI/UX.
                </p>
                <button className="btnSeeOptions animated-button1">
                <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <p>See Open Positions </p>
                  <MdArrowForward size={20} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Careers;
