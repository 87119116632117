import React from "react";
import "../Assets/Style/VideoSection.css";
import { IMAGES } from "../Utils";
import { MdClose, MdPlayArrow } from "react-icons/md";
import { Modal } from "react-bootstrap";

const VideoSection = () => {
  return (
    <>
      <div className="video-wrapper">
        <div className="row">
          <div className="col-lg-7 col-md-12 col-sm-12">
            <div className="video-section">
              <MdPlayArrow
                className="video-play"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              />
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12">
            <div className="video-img-section">
              <img src={IMAGES.VideoImg2} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-fullscreen-sm-down video-modal-dialog">
        <div
          class="modal fade video-modal"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content video-modal-content">
              <button
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                className="video-model-close-btn"
              >
                <MdClose size={30}/>
              </button>

              <div class="modal-body">
              <iframe width="800" height="500" src="https://www.youtube.com/embed/m0-5vTUv250" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
   
    </>
  );
};

export default VideoSection;
