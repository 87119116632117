import { Carousel } from "react-bootstrap";
import React from "react";
import { IMAGES } from "../Utils";
import "../Assets/Style/SliderSection.css";
import { useNavigate } from "react-router-dom";

const SliderSection = () => {

  const navigate = useNavigate();
  
  return (
    <>
      <div className="slider-desk">
        <Carousel controls={false} indicators={false} className="relative">
        <Carousel.Item interval={200000}>
            <img src={IMAGES.slider3} alt="Image1" className="slider-img" />
            <div className="digital-marketing-slider-text-right">
              <p className="p1">We are Solutions Oriented</p>
              <p className="slider-bold-text">
                {" "}
                DIGITAL <br /> MARKETING
              </p>

              <p className="p2">
                We can assist you if you want to grow your business.
              </p>
              <button className="animated-button1" onClick={()=>navigate("/contact")}>
                <span></span>
                <span></span>
                <span></span>
                <span></span> Get In Touch
              </button>
            </div>
          </Carousel.Item>

          <Carousel.Item interval={200000}>
            <img src={IMAGES.slider1} alt="Image1" className="slider-img" />
            <div className="digital-marketing-slider-text">
              <p className="p1">The Top Level</p>
              <p className="slider-bold-text">Web Design &</p>
              <p className="slider-bold-text">Development</p>
              <p className="p12">Company</p>
              <p className="p2">
                we offer website design and development services.
              </p>
              <button className="animated-button1" onClick={()=>navigate("/contact")}>
                <span></span>
                <span></span>
                <span></span>
                <span></span> Enquiry now
              </button>
            </div>
          </Carousel.Item> 

          <Carousel.Item interval={200000}>
            <img src={IMAGES.slider2} alt="Image1" className="slider-img" />
            <div className="digital-marketing-slider-text-gd">
              <p className="p1">The India's Best </p>
              <p className="slider-bold-text">
                Graphic <br /> Designing
              </p>

              <p className="p2">
                We offer Unique graphic design services for your business.
              </p>
              <button className="animated-button1" onClick={()=>navigate("/contact")}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Learn More
              </button>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>

      <div className="slider-mb">
        <Carousel controls={false} indicators={false} className="relative">
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-img"
              src={IMAGES.GraphicsHomeCard}
              alt="First slide"
            />
            <div className="carousel-text">
              <div className="carousel-inner-wrapper">
                <h3>Pune Digital Internet Marketing Agency</h3>
                <p>Grow Your ROI With the best digital Marketing solution</p>
              </div>
            </div>
          </Carousel.Item>

          {/* <Carousel.Item>
          <img
            className="d-block w-100 carousel-img"
            src={IMAGES.Slider1}
            alt="Third slide"
          />
          <div className="carousel-text">
            <h1>Pune Digital Internet Marketing Agency</h1>
            <p>Grow Your ROI With the best digital Marketing solution</p>
          </div>
        </Carousel.Item> */}
        </Carousel>
      </div>
    </>
  );
};

export default SliderSection;
