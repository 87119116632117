import "../Assets/Style/ContactUs.css";
import { CiFacebook, CiLinkedin, CiTwitter } from "react-icons/ci";
import { RxInstagramLogo } from "react-icons/rx";
import { ImPinterest2 } from "react-icons/im";
import {
  MdOutlineLocationOn,
  MdOutlineMarkEmailRead,
  MdOutlinePhoneInTalk,
} from "react-icons/md";
import { useEffect } from "react";
import { useState } from "react";
import { dthrilliumail } from "../config/api";
import { Helmet } from "react-helmet";
import { seoTitle } from "../Utils/SEO";

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [state, setState] = useState({
    ContactID: 0,
    FirstName: "",
    LastName: "",
    EmailID: "",
    Mobile: "",
    SelectOption: "",
    Service: "",
    Products: "",
    Training: "",
    Message: "",
    CreatedBy: 0,
    errors: [],
  });
  const [service, setService] = useState(false);
  const [product, setProduct] = useState(false);
  const [training, setTraining] = useState(false);

  const handlechnageDrpOption = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      errors: { ...state.errors, [e.target.name]: "" },
    });
    if (e.target.value === "service") {
      setService(true);
      setProduct(false);
      setTraining(false);

      setState((state.Products = ""), (state.Training = ""));
      setState({ ...state, [e.target.name]: e.target.value });
    }
    if (e.target.value === "products") {
      setProduct(true);
      setTraining(false);
      setService(false);

      setState((state.Service = ""), (state.Training = ""));
      setState({ ...state, [e.target.name]: e.target.value });
    }
    if (e.target.value === "training") {
      setProduct(false);
      setTraining(true);
      setService(false);

      setState((state.Service = ""), (state.Products = ""));
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };
  const validate = () => {
    const errors = {};
    let IsValid = true;
    const regex_name = /^[A-Za-z\s]+$/;
    const regex_email = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const Mobilenumber = /^[7-9][0-9]/;

    if (!state.FirstName) {
      IsValid = false;
      errors["FirstName"] = "First Name Is Required";
    } else if (state.FirstName.length < 2) {
      IsValid = false;
      errors["FirstName"] = "Must Be 15 Characters ";
    } else if (!state.FirstName.match(regex_name)) {
      IsValid = false;
      errors["FirstName"] = "Please Enter Valid Name";
    } else {
      errors["FirstName"] = "";
    }

    if (!state.LastName) {
      IsValid = false;
      errors["LastName"] = "Last Name Is Required";
    } else if (state.LastName.length < 2) {
      IsValid = false;
      errors["LastName"] = "Please Enter Valid Name";
    } else {
      errors["LastName"] = "";
    }

    if (!state.Mobile) {
      IsValid = false;
      errors["Mobile"] = "Mobile number Is Required";
    } else if (state.Mobile.length < 10 || state.Mobile.length > 10) {
      IsValid = false;
      errors["Mobile"] = "Mobile Number Must Have 10 Digits";
    } else if (!state.Mobile.match(Mobilenumber)) {
      IsValid = false;
      errors["Mobile"] = "Please Enter valid Mobile Number";
    } else {
      errors["Mobile"] = "";
    }

    if (!state.EmailID) {
      IsValid = false;
      errors["EmailID"] = "Email ID Is Required";
    } else if (!state.EmailID.match(regex_email)) {
      IsValid = false;
      errors["EmailID"] = "Please Enter valid Email id";
    } else {
      errors["EmailID"] = "";
    }

    if (!state.SelectOption) {
      IsValid = false;
      errors["SelectOption"] = "Please Select Option ";
    } else {
      errors["SelectOption"] = "";
    }

    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };
  const clearState = () => {
    setState({
      ContactID: 0,
      FirstName: "",
      LastName: "",
      EmailID: "",
      Mobile: "",
      SelectOption: "",
      Service: "",
      Products: "",
      Training: "",
      Message: "",
      CreatedBy: 0,
    });
  };

  const handleSubmit = async () => {
    console.log("submit-->", state);
    if (validate()) {
      await dthrilliumail(state).then((res) => {
        console.log("contact--->", res[0]);
        if (res.length == 1) {
          alert("You are Successfully Registed with us...!!");
          clearState();
        }
      });
    } else {
      alert("Please Enter Proper Details.!");
    }
  };
  return (
    <>
      <Helmet>
        <title>{seoTitle.Contact}</title>
        {/* <meta name="keywords" content={seoKeywords.Home} /> */}
        {/* <meta name="description" content={seoDecription.Home} /> */}
      </Helmet>
      <div>
        <div className="contactUs">
          <div className="container">
            <div className="row">
              <div className="contactUs-section">
                <div className="col-lg-6">
                  <div className="contactInfo">
                    <div>
                      <h3>
                        To Make Requests For Further Information,
                        <strong>Contact Us</strong> Via Our
                        <br />
                        Social Channels.
                      </h3>
                      <br />
                      <p>
                        We just need a couple of hours! <br /> No more than 2
                        working days since receiving your issue ticket.
                      </p>
                    </div>
                    <div>
                      <div className="social-icon-section">
                        <div>
                          <CiFacebook size={25} className="social-icons" />
                        </div>
                        <div>
                          <RxInstagramLogo size={25} className="social-icons" />
                        </div>
                        <div>
                          <CiTwitter size={25} className="social-icons" />
                        </div>
                        <div>
                          <CiLinkedin size={25} className="social-icons" />
                        </div>
                        <div>
                          <ImPinterest2 size={21} className="social-icons" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="contactForm">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="inputWrapper">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First name"
                            aria-label="First name"
                            name="FirstName"
                            value={state.FirstName}
                            onChange={handlechnageDrpOption}
                          />
                        </div>
                        {state.errors ? (
                          <p className="errors">{state.errors.FirstName}</p>
                        ) : null}
                      </div>
                      <div className="col-lg-6">
                        <div className="inputWrapper">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last name"
                            aria-label="Last name"
                            name="LastName"
                            value={state.LastName}
                            onChange={handlechnageDrpOption}
                          />
                        </div>
                        {state.errors ? (
                          <p className="errors">{state.errors.LastName}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="inputWrapper">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email ID"
                            aria-label="email"
                            name="EmailID"
                            value={state.EmailID}
                            onChange={handlechnageDrpOption}
                          />
                        </div>
                        {state.errors ? (
                          <p className="errors">{state.errors.EmailID}</p>
                        ) : null}
                      </div>
                      <div className="col-lg-6">
                        <div className="inputWrapper">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone"
                            aria-label="Phone"
                            name="Mobile"
                            value={state.Mobile}
                            onChange={handlechnageDrpOption}
                          />
                        </div>
                        {state.errors ? (
                          <p className="errors">{state.errors.Mobile}</p>
                        ) : null}
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="inputWrapper">
                            <select
                              className="form-select"
                              name="SelectOption"
                              onChange={handlechnageDrpOption}
                              value={state.SelectOption}
                            >
                              <option>-- Select Option --</option>
                              <option value="service">Services</option>
                              <option value="products">Products</option>
                              <option value="training">IT Training</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {service ? (
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="inputWrapper">
                              <select
                                className="form-select"
                                name="Service"
                                value={state.Service}
                                onChange={handlechnageDrpOption}
                              >
                                <option value="selectType">
                                  -- Select Services --
                                </option>
                                <option value="web design">Web Design</option>
                                <option value="web development">
                                  Web development
                                </option>
                                <option value="digital marketing">
                                  Digital Marketing
                                </option>
                                <option value="graphics design">
                                  Graphics Design
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {product ? (
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="inputWrapper">
                              <select
                                className="form-select"
                                name="Products"
                                value={state.Products}
                                onChange={handlechnageDrpOption}
                              >
                                <option value="selectType">
                                  -- Select Products --
                                </option>
                                <option value=" School Management">
                                  School Management
                                </option>
                                <option value="SPA Management">
                                  SPA Management
                                </option>
                                <option value="Hospital Management">
                                  Hospital Management
                                </option>
                                <option value="Inventory Management">
                                  Inventory Management
                                </option>
                                <option value=" Payroll Management">
                                  Payroll Management
                                </option>
                                <option value="other">Other</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {training ? (
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="inputWrapper">
                              <select
                                className="form-select"
                                name="Training"
                                value={state.Training}
                                onChange={handlechnageDrpOption}
                              >
                                <option value="selectType">
                                  -- Select Training --
                                </option>
                                <option value="digital marketing">
                                  Web Design Training
                                </option>
                                <option value="graphics design">
                                  Web Development Training
                                </option>
                                <option value="web design">
                                  Digital Marketing Training
                                </option>
                                <option value="web development">
                                  Graphics Designing Training
                                </option>
                                <option value="staffing">Staffing</option>
                                <option value="recruitment">Recruitment</option>
                                <option value="other">Other</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="col-lg-12">
                        <div className="inputWrapper">
                          <textarea
                            type="textarea"
                            className="form-control"
                            placeholder="Message"
                            aria-label="message"
                            name="Message"
                            value={state.Message}
                            onChange={handlechnageDrpOption}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="btnSubmit">
                          <button
                            type="submit"
                            className="animated-button1"
                            onClick={handleSubmit}
                          >
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            Make Request
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <div className="container">
          <div className="officeLocation">
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="location">
                  <h3>Pune Office</h3>
                  <div className="addr-box-wrap">
                    <div className="d-flex align-items-start contactAddress">
                      <div className="locationIcon">
                        <MdOutlineLocationOn size={25} />
                      </div>
                      <div className="address">
                        <p>
                          203, Shri Krishna Appartment, Katepuram Chowk, Pimple
                          Gurav, Pune, Maharashtra - 411061
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start contactAddress">
                      <div className="phoneIcon">
                        <MdOutlinePhoneInTalk size={25} />
                      </div>
                      <div className="phone">
                        <p>+91-8668 747 836 </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start contactAddress">
                      <div className="EmailIcon">
                        <MdOutlineMarkEmailRead size={25} />
                      </div>
                      <div className="email">
                        <p>info@dthrill.com </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="location">
                  <h3>Kalyan Office</h3>
                  <div className="addr-box-wrap">
                    <div className="d-flex align-items-start contactAddress">
                      <div className="locationIcon">
                        <MdOutlineLocationOn size={25} />
                      </div>
                      <div className="address">
                        <p>
                          Shanti CHS, Office No-05, First Floor, Rambag Road
                          len-04, Kalyan (west), Thane - 421301
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start contactAddress">
                      <div className="phoneIcon">
                        <MdOutlinePhoneInTalk size={25} />
                      </div>
                      <div className="phone">
                        <p>+91-8668 747 836 </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start contactAddress">
                      <div className="EmailIcon">
                        <MdOutlineMarkEmailRead size={25} />
                      </div>
                      <div className="email">
                        <p>info@dthrill.com </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="location">
                  <h3>Jalgaon Office</h3>
                  <div className="addr-box-wrap">
                    <div className="d-flex align-items-start contactAddress">
                      <div className="locationIcon">
                        <MdOutlineLocationOn size={25} />
                      </div>
                      <div className="address">
                        <p>
                          2nd Floor, Akshay chamber, Nr Dr Agarwal hospital
                          chaufully National highway, service road, MJ College
                          area - 425002
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start contactAddress">
                      <div className="phoneIcon">
                        <MdOutlinePhoneInTalk size={25} />
                      </div>
                      <div className="phone">
                        <p>+91-8668 747 836 </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start contactAddress">
                      <div className="EmailIcon">
                        <MdOutlineMarkEmailRead size={25} />
                      </div>
                      <div className="email">
                        <p>info@dthrill.com </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="locationOnmap">
          <div className="row">
            <div className="col-lg-12">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.739413218603!2d73.81350597410143!3d18.585783367181165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9b6a24a37f9%3A0x5884348effab7a34!2sDThrill%20Software%20Solutions%20LLP%7C%20Digital%20Marketing%20Agency%20in%20Pune%20%7C%20Web%20Development%2C%20Web%20Design%2C%20Graphic%20design%20in%20Pune!5e0!3m2!1sen!2sin!4v1683269530047!5m2!1sen!2sin"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
