import React from "react";
import { IMAGES } from "../Utils";
import "../Assets/Style/ViewBlog.css";

function ViewBlog_Affilition() {
  return (
    <>
      {" "}
      <div className="viewBlogBody">
        <div className="row blogsBanner">
          <div className="blog-bg-banner">
            <div className="container blogText">
              <div>
                <h3>Blogs</h3>
                <h5>A directory of wonderful things</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="container ">
          <div className="viewBlogSection">
            <div className="row">
              <div className="col-lg-8">
                <div className="blogImage">
                  <img src={IMAGES.BlogInnerDM} alt=" img for blog" />
                </div>
                <div className="profile">
                  <ul>
                    {/* <li>DThrill Software Solutions LLP</li> */}
                    <li>Marketing</li>
                  </ul>
                </div>

                <div className="blogTitle">
                  <h3>Affiliate marketing using quora full case study</h3>
                </div>

                <div className="blogDescription">
                  <div className="blog-para">
                    <p>
                      Affiliate marketing is the easiest way to make money
                      online without having any website or blog. Anyone can do
                      this no matter how smart you are, how to deal with people
                      and how much difficulty you face in affiliate marketing.
                    </p>
                    <p>
                      Affiliate marketing simply promotes other people’s
                      products or services and then earns a commission. Today in
                      this post you’re going to learn about affiliate marketing
                      Using quora. How to start as a complete beginner step by
                      step fully guided. All the methods I’m going to share with
                      you are free of cost if you don’t pay anything.
                    </p>
                    <p>
                      Maybe you’re using a Facebook group Instagram and whatever
                      so on for affiliate marketing. I’m not against Facebook
                      and other social networks but the problem is that you’re
                      not getting better results with that.
                    </p>
                    <p>
                      If you want to get better results on the Facebook group
                      then I suggest you create your own Facebook group related
                      to your niches or categories. it is not my main topic you
                      can also search on google that how to create a Facebook
                      group it is so simple after Creating your own Facebook
                      group you must add People who are interested in your
                      niches.
                    </p>
                    <p>
                      When I started affiliate marketing I had no idea what to
                      do. I applied the same strategies to Facebook and then
                      joined different groups. After that, I posted many
                      affiliate marketing links of different Affiliate programs
                      like Digistore24, Jvzoo, and Amazon.
                    </p>
                    <p>
                      By the way, right now I’m a full-time Amazon affiliate
                      marketer. I also share my own amazon affiliate program
                      income with another post you must read because I explain
                      everything in detail that how I get started and gain
                      results fast with free traffic sources.
                    </p>
                    <p>
                      If you want to become successful in affiliate marketing
                      you must use all social networks. But Today In this blog
                      post I’m going to show you how to get your affiliate
                      commission very fast with a free traffic method called
                      Quora.
                    </p>
                    <p>
                      Quora is the most popular community website. If you’re
                      already an affiliate marketer or wanted to get started
                      with affiliate marketing then not using Quora is a big
                      opportunity for you that you missed. If you don’t know
                      about Quora I already explained about Quora you must read
                      this blog post. All of my amazon affiliate commission is
                      using quora free traffic. I use all the social networks
                      but Quora is only my main source of income.
                    </p>
                    <p>
                      The question is how to get those results by using quora.
                      Don’t worry I will explain everything in detail. Make sure
                      you must take action if not then it is your own mistake
                      and your own responsibilities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="recentStorySection">
                  <div>
                    <div className="recentStory">
                      <h3>Recent Stories</h3>
                    </div>

                    <div className="recentStoryList">
                      <div className="blogImage">
                        <img src={IMAGES.Compition} alt=" img for blog" />
                      </div>
                      <div className="recentBlogInfo">
                        <p>Adverto Just Pushes Deeper Into Agency</p>
                        <p>Apr 14, 2023</p>
                      </div>
                    </div>
                    <div className="recentStoryList">
                      <div className="blogImage">
                        <img src={IMAGES.Compition} alt=" img for blog" />
                      </div>
                      <div className="recentBlogInfo">
                        <p>Adverto Just Pushes Deeper Into Agency</p>
                        <p>Apr 14, 2023</p>
                      </div>
                    </div>
                  </div>
                  <div className="categories">
                    <div className="recentStory">
                      <h3>Categories</h3>
                    </div>
                    <ul>
                      <li>Agency</li>
                      <li>comuter</li>
                      <li>development</li>
                      <li>designer</li>
                      <li>Graphic Design</li>
                      <li>Digital Marketing</li>
                    </ul>
                  </div>

                  <div className="Tags">
                    <div className="recentStory">
                      <h3>Tags</h3>
                    </div>
                    <div className="row tagsList">
                      <div className="col-lg-12">
                        <a>Agency</a>
                      </div>
                      <div className="col-lg-12">
                        <a>Comuter</a>
                      </div>
                      <div className="col-lg-12">
                        <a>Development</a>
                      </div>
                      <div className="col-lg-12">
                        <a>Designer</a>
                      </div>
                      <div className="col-lg-12">
                        <a>Graphics Design</a>
                      </div>
                      <div className="col-lg-12">
                        <a>Digital Marketing</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewBlog_Affilition;
