import React from "react";
import "../Assets/Style/Footer.css";
import { IMAGES } from "../Utils";
import { CiFacebook, CiLinkedin, CiTwitter } from "react-icons/ci";
import { RxInstagramLogo } from "react-icons/rx";
import { ImPinterest2 } from "react-icons/im";
import { RiFacebookCircleLine } from "react-icons/ri";
import {
  MdLocationOn,
  MdCall,
  MdEmail,
  MdKeyboardArrowRight,
} from "react-icons/md";

import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsWhatsapp } from "react-icons/bs";


const FooterComponent = () => {
  const phoneNumber = "+918668747836";
  function handleDialClick() {
    window.open(`tel:${phoneNumber}`);
  }
  return (
    <>
      <div className="footer">
        <div className="footer-section">
          <div className="container ">
            <div className="row">
              <div className="col-lg-2 col-md-6 col-sm-12 itSolutions">
                <h4>IT Solutions</h4>
                <ul>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <Link to="/webdesign">Web Design</Link>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <Link to="/webdevelopment">Web Development</Link>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <Link to="/digitalmarketing">Digital Marketing </Link>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <Link to="/graphicdesign">Graphics Designing</Link>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <Link to="#">IT Trainning</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 itService">
                <h4>Products </h4>
                <ul>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <a href="#">School Management</a>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <a href="#">SPA Management</a>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <a href="#">Hospital Management</a>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <a href="#">Inventory Management</a>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <a href="#">Payroll Management</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 QuickLink">
                <h4>Quick Links</h4>
                <ul>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <Link to="/aboutus">About</Link>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <Link to="/">Career</Link>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 ittrainning">
                <h4>It Training</h4>
                <ul>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <Link to="/webdesign">Web Design</Link>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <Link to="/webdevelopment">Web Development</Link>
                  </li>

                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <Link to="/digitalmarketing">Digital Marketing</Link>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <Link to="/graphicdesign">Graphics Designing</Link>
                  </li>
                  {/* <li>
                  <MdKeyboardArrowRight size={20} />
                    <Link to="/"> College Projects</Link>
                  </li> */}
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <Link to="/">Implant Training</Link>
                  </li>
                  <li>
                    <MdKeyboardArrowRight size={20} />
                    <Link to="/">Internship</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 contact-through">
                <h4>Contact Through</h4>
                <div className="d-flex align-items-start contactAddress">
                  <div className="locationIcon">
                    <MdLocationOn size={25} />
                  </div>
                  <div className="address">
                    <p>
                      DThrill Software Solutions LLP
                      <br /> Office No. 202, Above Chaitanya Jewellers,
                      <br />
                      Katepuram Chowk, Navi Sangvi, Pimple Gurav, Pune, MH -
                      411061
                    </p>
                  </div>
                </div>

                <div
                  className="d-flex align-items-start contactAddress footer-mb"
                  onClick={handleDialClick}
                >
                  <div className="phoneIcon">
                    <MdCall size={25} />
                  </div>
                  <div className="phone">
                    <p>+91-8668 747 836 </p>
                  </div>
                </div>

                <div className="d-flex align-items-start contactAddress">
                  <div className="EmailIcon">
                    <MdEmail size={25} />
                  </div>
                  <div className="email">
                    <p>info@dthrill.com </p>
                  </div>
                </div>
              </div>
            </div>
            <hr className="hrTag" />
            <div className="footer-middle">
              <div className="row">
                <div className="col-lg-9 col-md-12 col-sm-12 Information">
                  <ul className="info-ul">
                    <li>
                      <MdKeyboardArrowRight size={20} />
                      <a href="/">Client Support</a>
                    </li>
                    <li>
                      <MdKeyboardArrowRight size={20} />
                      <a href="/">Privacy Policy</a>
                    </li>
                    <li>
                      <MdKeyboardArrowRight size={20} />
                      <a href="/">Terms of Use</a>
                    </li>
                    <li>
                      <MdKeyboardArrowRight size={20} />
                      <a href="/">Sitemap</a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 ourPartners">
                  <div>
                    <Carousel indicators={false} controls={false}>
                      {/* Dhanik
                      abhitaland
                      rajshri shahu foundation
                      abhita film production
                      Abuzz Web tech
                       */}
                      <Carousel.Item>
                        <img
                          className="partnerLogo"
                          src={IMAGES.Abhitaland}
                          alt="Abhitaland"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="partnerLogo"
                          src={IMAGES.AbhitaFilm}
                          alt="AbhitaFilm"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="partnerLogo"
                          src={IMAGES.Abuzz}
                          alt="Abuzz"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="partnerLogo"
                          src={IMAGES.Rajarshi}
                          alt="Rajarshi"
                        />
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row align-items-center">
            <div className="col-lg-2 col-md-6 col-sm-12 footer-logo">
              <img src={IMAGES.Dthrill_new} alt="logo" className="logo" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 copyRight">
              ©Copyright {new Date().getFullYear()}{" "}
              <strong style={{ color: "#dd1717" }}>DThrill</strong> All Rights
              Reserved.
            </div>
            <div className="col-lg-4 col-md-12 text-md-end footer-socialIcons">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/dthrillsoftwaresolutions"
                    target="_about"
                  >
                    <RiFacebookCircleLine
                      size={25}
                      className="social-icons facebook"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/developersthrill/"
                    target="_about"
                  >
                    <RxInstagramLogo size={25} className="social-icons insta" />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.linkedin.com/company/37807420/admin/"
                    target="_about"
                  >
                    <CiLinkedin size={25} className="social-icons linkedin" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://in.pinterest.com/dthrillsoftwaresolutions/"
                    target="_about"
                  >
                    <ImPinterest2 size={21} className="social-icons pintrest" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/SoftwareDthrill" target="_about">
                    <CiTwitter size={25} className="social-icons twitter" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <a href="https://wa.link/q7jivv" target="_about">
        <BsWhatsapp className="wp-float-btn" size={30} />
      </a>
    </>
  );
};

export default FooterComponent;
