import React, { useState } from "react";
import "../Assets/Style/WebDesign.css";
import { IMAGES } from "../Utils";
import { Carousel } from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ServicesContact from "../Pages/ServicesContact";
import { useEffect } from "react";
import RegisterationPopUp from "../Components/RegisterationPopUp";
import { Helmet } from "react-helmet";
import { seoDecription, seoKeywords, seoTitle } from "../Utils/SEO";

const WebDesign = () => {
  const [showModal, setShowModal] = useState(false);
  // const handleClose = () => setShowModal(false);

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => setShowModal(true), 50000);
  };
  useEffect(() => {
    setTimeout(() => setShowModal(true), 50000);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{seoTitle.WebDesign}</title>
        <meta name="keywords" content={seoKeywords.WebDesign} />
        <meta name="description" content={seoDecription.WebDesign} />
      </Helmet>
      <RegisterationPopUp
        show={showModal}
        setShow={setShowModal}
        handleClose={handleClose}
      />
      <div className="">
        <div className="optimal-solution">
          <div className="opt-wrapper">
            <div className="opt-left">
              <div className="opt-heading-section">
                {/* <p>DThrill - Optimal Solutions</p> */}
                <h3>We Excel In Delivering Optimal Solutions.</h3>
              </div>
              <Carousel controls={false}>
                <Carousel.Item>
                  <div className="opt-left-wrapper">
                    <div className="opt-inner-wrapper">
                      <img src={IMAGES.WebDesignIcon1} alt="" />

                      <h5>Affordable Web Design Agency</h5>
                      <p>
                        Our website design showcase illustrates our versatility
                        and commitment. We create affordable tailor-made design
                        solutions for businesses in a diverse range of
                        industries. DThrill is flexible and will mould the
                        production processes in a way that will compliment your
                        timeline and budget requirements.
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="opt-left-wrapper">
                    <div className="opt-inner-wrapper">
                      <img src={IMAGES.WebDesignIcon2} alt="" />

                      <h5>Affordable Web Design Agency</h5>
                      <p>
                        Our digital marketing services utilises methods like
                        email marketing, search engine optimisation (SEO), and
                        Google AdWords, so that we can drive the right traffic
                        to your site. Digital marketing takes the guesswork out
                        of advertising by targeting your exact demographic, and
                        increases opportunities.
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
            <div className="marq-wrapper">
              <marquee direction="up">
                <img
                  src={IMAGES.Marquee1}
                  alt=""
                  scrollamount="20"
                  className="marq-img"
                />
              </marquee>
              <marquee direction="down">
                <img
                  src={IMAGES.Marquee1}
                  alt=""
                  scrollamount="20"
                  className="marq-img"
                />
              </marquee>
            </div>
          </div>
        </div>
        <div>
          <div className="design-section">
            <div className="design-wrapper">
              <AnimationOnScroll animateIn="animate__fadeIn"></AnimationOnScroll>
              <img src={IMAGES.WebDesignSolution} alt="" />
              <div className="design-para-wrapper-odd">
                <AnimationOnScroll animateIn="animate__zoomIn">
                  <div className="design-para">
                    <img src={IMAGES.Abstract1} alt="" className="dot-1" />
                    <h3>Web Design Solutions</h3>
                    <p>
                      With our creative web design professionals at DThrill, you
                      can reach your brand vision easily. Determination of your
                      customers impression depends upon the design of your
                      website.
                    </p>
                    <p>
                      Although website design is changing constantly, we can
                      give you quality and time-tested design solutions.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>

          <div className="design-section">
            <div className="design-wrapper-even">
              <div className="design-para-wrapper-even">
                <AnimationOnScroll animateIn="animate__zoomIn">
                  <div className="design-para">
                    <img src={IMAGES.Abstract2} alt="" className="dot-2" />
                    <h3>DThrill Web Design</h3>
                    <p>
                      At DThrill, every aspect of our work reflects the passion
                      we have for fine, intelligent design. We have an
                      outstanding team of web developers who can create any
                      complicated and innovative websites. We give you a website
                      with superior output that matches your unique needs with
                      unparalleled web development services. Our web development
                      services company is here to support you, whether you are a
                      large, medium-sized or small company.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
              <AnimationOnScroll animateIn="animate__fadeIn">
                <img src={IMAGES.DThrillWebDesign} alt="" />
              </AnimationOnScroll>
            </div>
          </div>

          <div className="design-section">
            <div className="design-wrapper">
              <AnimationOnScroll animateIn="animate__fadeIn">
                <img src={IMAGES.ResponsiveWebDesign} alt="" />
              </AnimationOnScroll>
              <div className="design-para-wrapper-odd">
                <AnimationOnScroll animateIn="animate__zoomIn">
                  <div className="design-para">
                    <img src={IMAGES.Abstract1} alt="" className="dot-1" />
                    <h3> Responsive Web Design</h3>
                    <p>
                      Responsive Web Design As the patterns in web surfing
                      changing, online purchase often change drastically. Our
                      well established web design company can make the static
                      websites, with smooth pictures and graphics, that will
                      create a unbreakable bond with your customers. Responsive
                      websites can easily be accessed on any device.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>

          <div className="design-section">
            <div className="design-wrapper-even">
              <div className="design-para-wrapper-even">
                <AnimationOnScroll animateIn="animate__zoomIn">
                  <div className="design-para">
                    <img src={IMAGES.Abstract2} alt="" className="dot-2" />
                    <h3>Design Experience</h3>
                    <p>
                      We have experienced creative thinking developers &
                      designers, who have worked on various clients. We keep
                      ourselves ahead in a new designs, tools and go with the
                      trends, that means we provide you the latest visual
                      solutions. We don’t say it with words, we say it with
                      design.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
              <AnimationOnScroll animateIn="animate__fadeIn">
                <img src={IMAGES.DesignExperience} alt="" />
              </AnimationOnScroll>
            </div>
          </div>

          <div className="design-section">
            <div className="design-wrapper">
              <AnimationOnScroll animateIn="animate__fadeIn">
                <img src={IMAGES.Brand} alt="" />
              </AnimationOnScroll>
              <div className="design-para-wrapper-odd">
                <AnimationOnScroll animateIn="animate__zoomIn">
                  <div className="design-para">
                    <img src={IMAGES.Abstract1} alt="" className="dot-1" />
                    <h3> Brand</h3>
                    <p>
                      You can boost the credibility of your company and increase
                      lasting loyalty among customers through the creation of a
                      clear professional brand identity. An successful online
                      branding strategy will allow you to say whatever you have
                      to say about your business, by communicating your message
                      on the right platform to the right people.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
        </div>

        <ServicesContact />
      </div>
    </>
  );
};

export default WebDesign;
