import React, { useState } from "react";
import { IMAGES } from "../Utils";
import { RiAccountCircleLine } from "react-icons/ri";
import { TfiEmail } from "react-icons/tfi";
import { MdOutlinePhone } from "react-icons/md";

const ServicesContact = () => {
  const [state, setState] = useState({
    fullName: "",
    email: "",
    mobile: "",
    selectOption: "",
    service: "",
    products: "",
    training: "",
    message: "",
  });
  const [service, setService] = useState(false);
  const [product, setProduct] = useState(false);
  const [training, setTraining] = useState(false);
  const handlechnageDrpOption = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    if (e.target.value === "service") {
      setService(true);
      setProduct(false);
      setTraining(false);

      setState((state.products = ""), (state.training = ""));
      setState({ ...state, [e.target.name]: e.target.value });
    }
    if (e.target.value === "products") {
      setProduct(true);
      setTraining(false);
      setService(false);

      setState((state.service = ""), (state.training = ""));
      setState({ ...state, [e.target.name]: e.target.value });
    }
    if (e.target.value === "training") {
      setProduct(false);
      setTraining(true);
      setService(false);

      setState((state.service = ""), (state.products = ""));
      setState({ ...state, [e.target.name]: e.target.value });
    }
    console.log("handdle hcnage--->", state);
  };
  const handleSubmit = () => {
    console.log("ssubmit-->", state);
  };
  return (
    <>
      <div className="service-contact-section">
        <div className="row service-flex">
          <div className="col-lg-7 col-md-6 col-sm-12">
            <div className="service-contact-wrapper">
              <div className="service-contact-inner-wrapper">
                <div className="service-contact-heading">
                  <h3>Get In Touch</h3>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="service-contact-inputWrapper">
                      <input
                        type="text"
                        className="service-contact-form-control"
                        placeholder="Enter Full Name"
                        aria-label="Full Name"
                        name="fullName"
                        value={state.fullName}
                        onChange={handlechnageDrpOption}
                      />
                      <RiAccountCircleLine
                        size={20}
                        className="service-contact-input-icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="service-contact-inputWrapper">
                      <input
                        type="text"
                        className="service-contact-form-control"
                        placeholder="Enter Phone Number"
                        aria-label="Phone Number"
                        name="mobile"
                        value={state.mobile}
                        onChange={handlechnageDrpOption}
                      />
                      <MdOutlinePhone
                        size={20}
                        className="service-contact-input-icon"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="service-contact-inputWrapper">
                      <input
                        type="email"
                        className="service-contact-form-control"
                        placeholder="Enter Email Id"
                        aria-label="email"
                        name="email"
                        value={state.email}
                        onChange={handlechnageDrpOption}
                      />
                      <TfiEmail
                        size={20}
                        className="service-contact-input-icon"
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-lg-12">
                    <div className="service-contact-inputWrapper">
                      <select
                        className="service-contact-form-select"
                        name="selectOption"
                        onChange={handlechnageDrpOption}
                        value={state.selectOption}
                      >
                        <option>-- Select Training --</option>
                        <option value="service">Services</option>
                        <option value="products">Products</option>
                        <option value="training">IT Training</option>
                      </select>
                    </div>
                  </div>
                </div> */}
                {service ? (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="service-contact-inputWrapper">
                        <select
                          className="service-contact-form-select"
                          name="service"
                          value={state.service}
                          onChange={handlechnageDrpOption}
                        >
                          <option value="selectType">
                            -- Select Services --
                          </option>
                          <option value="web design">Web Design</option>
                          <option value="web development">
                            Web development
                          </option>
                          <option value="digital marketing">
                            Digital Marketing
                          </option>
                          <option value="graphics design">
                            Graphics Design
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                ) : null}

                {product ? (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="service-contact-inputWrapper">
                        <select
                          className="form-select"
                          name="products"
                          value={state.products}
                          onChange={handlechnageDrpOption}
                        >
                          <option value="selectType">
                            -- Select Products --
                          </option>
                          <option value=" School Management">
                            School Management
                          </option>
                          <option value="SPA Management">SPA Management</option>
                          <option value="Hospital Management">
                            Hospital Management
                          </option>
                          <option value="Inventory Management">
                            Inventory Management
                          </option>
                          <option value=" Payroll Management">
                            Payroll Management
                          </option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </div>
                  </div>
                ) : null}

                {training ? (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="service-contact-inputWrapper">
                        <select
                          className="form-select"
                          name="training"
                          value={state.training}
                          onChange={handlechnageDrpOption}
                        >
                          <option value="selectType">
                            -- Select Training --
                          </option>
                          <option value="digital marketing">
                            Web Design Training
                          </option>
                          <option value="graphics design">
                            Web Development Training
                          </option>
                          <option value="web design">
                            Digital Marketing Training
                          </option>
                          <option value="web development">
                            Graphics Designing Training
                          </option>
                          <option value="staffing">Staffing</option>
                          <option value="recruitment">Recruitment</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="row">
                  <div className="col-lg-12">
                    <div className="service-contact-inputWrapper">
                      <textarea
                        type="textarea"
                        className="service-contact-form-control"
                        placeholder="Message..."
                        aria-label="message"
                        name="message"
                        value={state.message}
                        onChange={handlechnageDrpOption}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="service-contact-inputWrapper service-contact-btnSubmit">
                      <button type="submit" class="animated-button1" onClick={handleSubmit}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6 col-sm-12">
            <div className="service-contact-img-wrapper">
              <img src={IMAGES.ContactLady} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesContact;
