import React from "react";
import { IMAGES } from "../Utils";
import "../Assets/Style/ViewBlog.css";

function ViewBlog_HowManyCourses() {
  return (
    <>
      <div className="viewBlogBody">
        <div className="row blogsBanner">
          <div className="blog-bg-banner">
            <div className="container blogText">
              <div>
                <h3>Blogs</h3>
                <h5>A directory of wonderful things</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="container ">
          <div className="viewBlogSection">
            <div className="row">
              <div className="col-lg-8">
                <div className="blogImage">
                  <img src={IMAGES.BlogHowMany} alt=" img for blog" />
                </div>
                <div className="profile">
                  <ul>
                    {/* <li>DThrill Software Solutions LLP</li> */}
                    <li>Digital Marketing</li>
                  </ul>
                </div>

                <div className="blogTitle">
                  <h3>How Many Courses are there in Digital Marketing?</h3>
                </div>

                <div className="blogDescription">
                  <div className="blog-para">
                    <p>
                      The number of courses in digital marketing can vary widely
                      depending on the institution, platform, and the specific
                      focus of the course. Digital marketing is a broad field
                      that encompasses various aspects such as search engine
                      optimization (SEO), social media marketing, email
                      marketing, content marketing, pay-per-click advertising,
                      analytics, and more.
                    </p>
                  </div>
                  <div className="blog-para">
                    <h5>
                      Some common types of digital marketing courses include:
                    </h5>
                  </div>
                  <div className="blog-para">
                    <h5> 1. Fundamentals of Digital Marketing:</h5>
                    <p>
                      These classes give an introduction to the ideas and
                      tactics of digital marketing.
                    </p>
                  </div>
                  <div className="blog-para">
                    <h5>2. SEO courses:</h5>
                    <p>
                      The principles and techniques of search engine
                      optimisation are the main topics of these courses.
                    </p>
                  </div>
                  <div className="blog-para">
                    <h5>3. Social Media Marketing Courses:</h5>
                    <p>
                      Marketing on websites like Facebook, Twitter, Instagram,
                      and LinkedIn is covered in these courses.{" "}
                    </p>
                  </div>
                  <div className="blog-para">
                    <h5>4. Content Marketing Courses:</h5>
                    <p>
                      These programs show students how to produce and
                      disseminate useful material for marketing reasons.
                    </p>
                  </div>
                  <div className="blog-para">
                    <h5>5. Email Marketing Courses:</h5>
                    <p>These courses examine email marketing techniques.</p>
                  </div>
                  <div className="blog-para">
                    <h5>6. PPC (Pay-Per-Click) Advertising Courses:</h5>
                    <p>
                      These classes cover paid advertising on websites like
                      Facebook Ads and Google Ads.
                    </p>
                  </div>
                  <div className="blog-para">
                    <h5>7. Analytics and Data Analysis Courses:</h5>
                    <p>
                      The performance of digital marketing efforts is the main
                      topic of these courses.
                    </p>
                  </div>
                  <div className="blog-para">
                    <h5>8. AE-commerce Marketing Courses:</h5>
                    <p>
                      These programmes are designed specifically for internet
                      product or service marketing.
                    </p>
                  </div>
                  <div className="blog-para">
                    <h5>9. Affiliate Marketing Courses:</h5>
                    <p>
                      The concepts and tactics of affiliate marketing are
                      covered in these courses.
                    </p>
                  </div>
                  <div className="blog-para">
                    <h5>10. Mobile Marketing Courses:</h5>
                    <p>
                      These courses provide a strong emphasis on mobile device
                      marketing.
                    </p>
                  </div>
                  <p>
                    The world of digital marketing is expansive, encompassing
                    various specialized areas. The courses mentioned above
                    provide a glimpse into the diverse opportunities available
                    for those aspiring to build a career in this field.
                    Depending on your interests and career goals, you can choose
                    from introductory courses to advanced, specialized ones.
                    Continuous learning and staying updated with the latest
                    digital marketing trends and tools are essential for success
                    in this ever-evolving industry. Whether you're a novice or
                    an experienced marketer, there's a digital marketing course
                    out there to help you thrive in the digital landscape.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="recentStorySection">
                  <div>
                    <div className="recentStory">
                      <h3>Recent Stories</h3>
                    </div>

                    <div className="recentStoryList">
                      <div className="blogImage">
                        <img src={IMAGES.Compition} alt=" img for blog" />
                      </div>
                      <div className="recentBlogInfo">
                        <p>Adverto Just Pushes Deeper Into Agency</p>
                        <p>Apr 14, 2023</p>
                      </div>
                    </div>
                    <div className="recentStoryList">
                      <div className="blogImage">
                        <img src={IMAGES.Compition} alt=" img for blog" />
                      </div>
                      <div className="recentBlogInfo">
                        <p>Adverto Just Pushes Deeper Into Agency</p>
                        <p>Apr 14, 2023</p>
                      </div>
                    </div>
                  </div>
                  <div className="categories">
                    <div className="recentStory">
                      <h3>Categories</h3>
                    </div>
                    <ul>
                      <li>Agency</li>
                      <li>comuter</li>
                      <li>development</li>
                      <li>designer</li>
                      <li>Graphic Design</li>
                      <li>Digital Marketing</li>
                    </ul>
                  </div>

                  <div className="Tags">
                    <div className="recentStory">
                      <h3>Tags</h3>
                    </div>
                    <div className="row tagsList">
                      <div className="col-lg-12">
                        <a>Agency</a>
                      </div>
                      <div className="col-lg-12">
                        <a>Comuter</a>
                      </div>
                      <div className="col-lg-12">
                        <a>Development</a>
                      </div>
                      <div className="col-lg-12">
                        <a>Designer</a>
                      </div>
                      <div className="col-lg-12">
                        <a>Graphics Design</a>
                      </div>
                      <div className="col-lg-12">
                        <a>Digital Marketing</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewBlog_HowManyCourses;
