import React from "react";
import "../Assets/Style/HowWeWork.css";
import { IMAGES } from "../Utils";
import { AnimationOnScroll } from "react-animation-on-scroll";

const HowWeWorks = () => {
  return (
    <>
      <section className="howWork-section">
        <div className="howWork-inner-section">
          <div className="howWork-title-wrapper">
            <div className="howWork-title">
              <span className="border-left"></span>
              <p>How We work</p>
              <span className="border-right"></span>
              <AnimationOnScroll animateIn="animate__zoomIn">
                <h3 className="howWork-sub-title">
                  How it helps your business succeed
                </h3>
              </AnimationOnScroll>{" "}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <AnimationOnScroll animateIn="animate__fadeInLeft" delay={100}>
                <div className="howWork-wrapper">
                  <div className="howWork-Wrapper-inner">
                    <div className="icon-outer-section">
                      <div className="icon-bg">
                        <img
                          src={IMAGES.HowWorkDiscuss}
                          alt=""
                          className="howWork-icon"
                        />
                      </div>
                      <div className="angle-icon">
                        <img src={IMAGES.HowWorkArrow} />
                      </div>
                    </div>
                    <div className="howWok-main-title">
                      <h5>Discussion</h5>
                      <p>
                        We meet customers in set place to discuss the details
                        about needs and demands before proposing a plan.
                      </p>
                    </div>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <AnimationOnScroll animateIn="animate__fadeInLeft" delay={300}>
                <div className="howWork-wrapper">
                  <div className="howWork-Wrapper-inner">
                    <div className="icon-outer-section">
                      <div className="icon-bg">
                        <img
                          src={IMAGES.HowWorkIdea}
                          alt=""
                          className="howWork-icon"
                        />
                      </div>{" "}
                      <div className="angle-icon">
                        <img src={IMAGES.HowWorkArrow} />
                      </div>
                    </div>
                    <div className="howWok-main-title">
                      <h5>Idea & Concept</h5>
                      <p>
                        Our experts come up with all kinds of ideas and
                        initiatives for delivering the best solutions for IT
                        services chosen.
                      </p>
                    </div>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <AnimationOnScroll animateIn="animate__fadeInLeft" delay={500}>
                <div className="howWork-wrapper">
                  <div className="howWork-Wrapper-inner">
                    <div className="icon-outer-section">
                      <div className="icon-bg">
                        <img
                          src={IMAGES.HowWorkCode}
                          alt=""
                          className="howWork-icon"
                        />
                      </div>{" "}
                      <div className="angle-icon">
                        <img src={IMAGES.HowWorkArrow} />
                      </div>
                    </div>
                    <div className="howWok-main-title">
                      <h5>Code & Testing</h5>
                      <p>
                        After agreeing on the ideas and plans, we will conduct
                        as scheduled and give comments on the results &
                        adaptations.
                      </p>
                    </div>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <AnimationOnScroll animateIn="animate__fadeInLeft" delay={700}>
                <div className="howWork-wrapper">
                  <div className="howWork-Wrapper-inner">
                    <div className="icon-outer-section">
                      <div className="icon-bg">
                        <img
                          src={IMAGES.HowWorkLaunch}
                          alt=""
                          className="howWork-icon"
                        />
                      </div>
                    </div>
                    <div className="howWok-main-title">
                      <h5>Launch & Go Live</h5>
                      <p>
                        Once the final plan is approved, everything will be
                        conducted according to the agreed contract.
                      </p>
                    </div>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HowWeWorks;
