import React from "react";
import "../Assets/Style/CapableShowCase.css";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";

const CapableShowCase = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <div className="capable-overlay">
            <div className="capable-wrapper">
              <p className="capable-sub-title">Capabilities</p>
              <AnimationOnScroll animateIn="animate__zoomIn">
                <h3>Team management. Brand strategy.</h3>
              </AnimationOnScroll>
              <div className="capable-box-arrow">
                <button
                  className="animated-button1"
                  onClick={() => navigate("/services")}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <p className="view">View Services</p>
                  <FiArrowRight size={18} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12">
          <div className="showcase-overlay">
            <div className="showcase-wrapper">
              <p className="showcase-sub-title">Showcase</p>
              <AnimationOnScroll animateIn="animate__zoomIn">
                <h3>Our most recent digital and strategy projects.</h3>
              </AnimationOnScroll>
              <div className="showcase-box-arrow">
                <button className="animated-button1"> <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <p className="view">View Projects</p>
                  <FiArrowRight size={18} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CapableShowCase;
