import React from "react";
import { IMAGES } from "../Utils";
import "../Assets/Style/ViewBlog.css";

function ViewBlog_digitalM() {
  return (
    <>
      <div className="viewBlogBody">
        <div className="row blogsBanner">
          <div className="blog-bg-banner">
            <div className="container blogText">
              <div>
                <h3>Blogs</h3>
                <h5>A directory of wonderful things</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="container ">
          <div className="viewBlogSection">
            <div className="row">
              <div className="col-lg-8">
                <div className="blogImage">
                  <img src={IMAGES.BlogInnerDM} alt=" img for blog" />
                </div>
                <div className="profile">
                  <ul>
                    {/* <li>DThrill Software Solutions LLP</li> */}
                    <li>Digital Marketing</li>
                  </ul>
                </div>

                <div className="blogTitle">
                  <h3>Digital marketing is essential for small companies</h3>
                </div>

                <div className="blogDescription">
                  <div className="blog-para">
                    <p>
                      You're a company owner? Super! wait, wait. Have you ever
                      been thinking where you're on the market? Do you want to
                      make your brand or service more popular on the market? Do
                      you want your revenue to grow as much every year? We are
                      here to assist you if your response isn't! DThrill
                      Software Solutions is a reputable, seasoned digital
                      marketing company that offers Pune digital marketing
                      services. We are committed to providing results-oriented
                      services that help you access your website, spread
                      consumer awareness of your brand and increase your
                      revenues. Having digital marketing for your company, here
                      are a few ways to use:
                    </p>
                  </div>
                  <div className="blog-para">
                    <h5>Work with a business 24/7 :</h5>
                    <p>
                      With a company online, you guarantee that customers are
                      available all day long. You have nothing to worry about.
                      Most of us also have various surfing habits and schedules.
                      Making sure you are available online gives the customers a
                      sense of credibility. They will directly contact you at
                      all times of the day and provide suggestions or questions.
                      This will contribute to building customer relations and
                      also create trust.
                    </p>
                  </div>
                  <div className="blog-para">
                    <h5>Enhance your visibility online :</h5>
                    <p>
                      You don't know if people really know your brand when you
                      own a shop offline. When we see that there is a high level
                      of competition in all sectors and companies, it is vital
                      that we have an online vision of the market so that people
                      can learn more about your services or brand in your area
                      or on the internet and how well you can meet them. Digital
                      marketing assists you in building your brand picture.
                      Before you take your purchasing decisions, it is essential
                      to continuously develop your online presence and create
                      confidence among your customers as people search for
                      online reviews. Word of mouth is said to play a major part
                      in enhancing the client base. Good feedback and online
                      presence will boost your company's lack of areas. Faithful
                      brands are more absorbed than others. "In Forbes's opinion
                      (2016), 90% of clients read online reviews before they
                      visit a company and 74% claim that good reviews increase
                      their trust in a local company." Get in touch with the
                      best digital marketing agency in Pune, DThrill Software
                      Solutions to increase the image of the brand and create a
                      strong digital marketing credibility online.
                    </p>
                  </div>
                  <div className="blog-para">
                    <h5>
                      Measure the results with digital marketing instruments :
                    </h5>
                    <p>
                      Since small firms do not know how to grow their business
                      and do not know where to start planning. Online marketing
                      tools are an opportunity for small companies
                    </p>
                  </div>
                  <div className="blog-para">
                    <h3>Online marketing tools are as successful as follows - </h3>
                  </div>
                  <div className="blog-para">
                    <h5>Google Analytics :</h5>
                    <p>
                      You can gain insight into your company with this method.
                      It is an online platform that lets you learn more about
                      your website by different reports. You can monitor the
                      visitors' behaviour, how long they've spent and how long
                      they turn to sales, like they came to your website. You
                      can also track the success of your publicity with this
                      tool and boost it if necessary to fulfill your goals.
                    </p>
                  </div>
                  <div className="blog-para">
                    <h5>Google Keyword Planner :</h5>
                    <p>
                      Google Keyword Planner is an SEO app. It allows you to
                      track the keywords used in the visitors' search engines.
                      You can review and get a list of all your business
                      keywords, check the competition, and if necessary, average
                      monthly quest. This gives you an obvious idea of which
                      keyword can affect your websites, blogs or sites and
                      function well. “Google Analytics is the top SEO tool used
                      by marketers by Hubspot(2020)”
                    </p>
                    <p>
                      These digital marketing tools help you monitor and develop
                      the usability and the audience of your website. Digital
                      marketing means that several outlets are used Digital
                      Marketing allows you to easily promote or build your
                      website with various platforms. This approach enables
                      people on different networks to communicate and allows
                      customers from a different platform to learn about it.
                      There are many ways to execute your digital marketing
                      strategy and push forward with a broad range of customer
                      base through social media sites such as Facebook,
                      Instagram, Linkedin, Twitter or Pinterest and many more
                      along with email marketing, SEO, etc. This approach will
                      help you to find your potential customers and boost
                      conversions.
                    </p>
                    <p>
                      {" "}
                      A Hubspot study published Social Media Marketing
                      Statistics 2020
                    </p>
                    <p>
                      {" "}
                      There are about 280 million Facebook users in India (2020)
                      More than 80 million small companies are using free
                      business tools from Facebook. If you are a small company
                      that aims to start your Digital Marketing company, we
                      provide cost-effective digital marketing services from
                      Dimakh Consultants to improve your conversion.
                    </p>
                  </div>
                  <div className="blog-para">
                    <h5>Conclusions :</h5>
                    <p>
                      As competition grows every day, it is vital to stay ahead
                      of your business rivals by providing digital marketing
                      services to your business. Furthermore, this approach is
                      cost-effective and can be applied by small companies
                      rather than conventional marketing. It certainly helps you
                      to engage more consumers by having digital marketing
                      campaigns and building trust in the market opening doors
                      to further opportunities. Contact the leading digital
                      marketing company DThrill Software Solutions today in
                      Pune!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="recentStorySection">
                  <div>
                    <div className="recentStory">
                      <h3>Recent Stories</h3>
                    </div>

                    <div className="recentStoryList">
                      <div className="blogImage">
                        <img src={IMAGES.Compition} alt=" img for blog" />
                      </div>
                      <div className="recentBlogInfo">
                        <p>Adverto Just Pushes Deeper Into Agency</p>
                        <p>Apr 14, 2023</p>
                      </div>
                    </div>
                    <div className="recentStoryList">
                      <div className="blogImage">
                        <img src={IMAGES.Compition} alt=" img for blog" />
                      </div>
                      <div className="recentBlogInfo">
                        <p>Adverto Just Pushes Deeper Into Agency</p>
                        <p>Apr 14, 2023</p>
                      </div>
                    </div>
                  </div>
                  <div className="categories">
                    <div className="recentStory">
                      <h3>Categories</h3>
                    </div>
                    <ul>
                      <li>Agency</li>
                      <li>comuter</li>
                      <li>development</li>
                      <li>designer</li>
                      <li>Graphic Design</li>
                      <li>Digital Marketing</li>
                    </ul>
                  </div>

                  <div className="Tags">
                    <div className="recentStory">
                      <h3>Tags</h3>
                    </div>
                    <div className="row tagsList">
                      <div className="col-lg-12">
                        <a>Agency</a>
                      </div>
                      <div className="col-lg-12">
                        <a>Comuter</a>
                      </div>
                      <div className="col-lg-12">
                        <a>Development</a>
                      </div>
                      <div className="col-lg-12">
                        <a>Designer</a>
                      </div>
                      <div className="col-lg-12">
                        <a>Graphics Design</a>
                      </div>
                      <div className="col-lg-12">
                        <a>Digital Marketing</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewBlog_digitalM;
