import React, { useState } from "react";
import "../Assets/Style/DigitalMarketing.css";
import { IMAGES } from "../Utils";
import {
  MdAdd,
  MdContactSupport,
  MdMenu,
  MdOutlineBusiness,
  MdRemove,
} from "react-icons/md";
import {
  GiArchiveResearch,
  GiConversation,
  GiRegeneration,
} from "react-icons/gi";
import { SiMicrostrategy } from "react-icons/si";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ServicesContact from "../Pages/ServicesContact";
import { useEffect } from "react";
import RegisterationPopUp from "../Components/RegisterationPopUp";
import { Helmet } from "react-helmet";
import { seoDecription, seoKeywords, seoTitle } from "../Utils/SEO";

const DigitalMarketing = () => {
  const [showModal, setShowModal] = useState(false);
  // const handleClose = () => setShowModal(false);

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => setShowModal(true), 50000);
  };
  useEffect(() => {
    setTimeout(() => setShowModal(true), 50000);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [show, setShow] = useState(true);
  const [subMenuOpen, setSubMenuOpen] = useState(-1);
  const toggleMenu = (x) => setSubMenuOpen(subMenuOpen === x ? -1 : x);

  const [partnerShow, setPartnerShow] = useState(true);

  const funPartnerShow = () => {
    setPartnerShow(true);
    setInnovationShow(false);
    setStrategyShow(false);
    setFunLeadShow(false);
    setResearchShow(false);
    setSupportShow(false);
  };

  const [InnovationShow, setInnovationShow] = useState(false);

  const funInnovationShow = () => {
    setInnovationShow(true);
    setPartnerShow(false);
    setStrategyShow(false);
    setFunLeadShow(false);
    setResearchShow(false);
    setSupportShow(false);
  };

  const [StrategyShow, setStrategyShow] = useState(false);

  const funStrategyShow = () => {
    setStrategyShow(true);
    setPartnerShow(false);
    setInnovationShow(false);
    setFunLeadShow(false);
    setResearchShow(false);
    setSupportShow(false);
  };
  const [LeadShow, setFunLeadShow] = useState(false);

  const funLeadShow = () => {
    setFunLeadShow(true);
    setPartnerShow(false);
    setInnovationShow(false);
    setStrategyShow(false);
    setResearchShow(false);
    setSupportShow(false);
  };
  const [ResearchShow, setResearchShow] = useState(false);

  const funResearchShow = () => {
    setResearchShow(true);
    setPartnerShow(false);
    setInnovationShow(false);
    setStrategyShow(false);
    setFunLeadShow(false);
    setSupportShow(false);
  };
  const [SupportShow, setSupportShow] = useState(false);

  const funSupportShow = () => {
    setSupportShow(true);
    setPartnerShow(false);
    setInnovationShow(false);
    setStrategyShow(false);
    setFunLeadShow(false);
    setResearchShow(false);
  };

  return (
    <>
      <Helmet>
        <title>{seoTitle.digitalMarking}</title>
        <meta name="keywords" content={seoKeywords.digitalMarking} />
        <meta name="description" content={seoDecription.digitalMarking} />
      </Helmet>
      <RegisterationPopUp
        show={showModal}
        setShow={setShowModal}
        handleClose={handleClose}
      />
      <div className="digital-section">
        <div className="container">
          <div className="digital-wrapper">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="digital-inner-wrapper">
                  <div className="digital-text">
                    <h3>
                      We will help you boost organic traffic on your platform
                      and transformation through an ongoing SEO strategy.
                    </h3>
                    <AnimationOnScroll animateIn="animate__fadeInUp">
                      <p>
                        The most relavant websites for users search query should
                        be the top ranking search. You should focus on the
                        strategy on attracting people who will engage and
                        convert. Your landing pages must be customised to the
                        user to have the best possible user experience, as well
                        as the right phrases.
                      </p>
                      <p>
                        Our search engine optimisation campaign is conducted by
                        our search engine and review of your site, target
                        audience and competition. We will then create a plan to
                        update the content, improve the design and improve the
                        health of your website and promote your website
                        carefully online.
                      </p>
                    </AnimationOnScroll>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="digital-img-section">
                  {/* <AnimationOnScroll animateIn="animate__fadeIn"> */}
                  <img
                    src={IMAGES.DMSEOStrategy}
                    alt=""
                    className="digital-img1"
                  />
                  {/* </AnimationOnScroll> */}
                </div>
              </div>
            </div>
          </div>
          <div className="digital-wrapper">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="digital-img-section">
                  <AnimationOnScroll animateIn="animate__fadeIn">
                    <img src={IMAGES.DMAudit} alt="" className="digital-img1" />
                  </AnimationOnScroll>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="digital-inner-wrapper">
                  <div className="digital-text">
                    <h3>
                      Research. <span>Audit.</span> Analysis.
                    </h3>
                    <AnimationOnScroll animateIn="animate__fadeInUp">
                      <p>
                        There is always a new company and website, so we create
                        DThrill plan for DThrill for our customers.
                      </p>
                      <p>
                        In order to achieve results, we need to know your
                        business, your goals and your competition. In order to
                        evaluate your current success in the search, we start
                        with keywords and competition analysis. A analysis of
                        the websites of your rivals would be useful on how to
                        take full advantage of yours. This highlights strengths
                        and drawbacks associated with the new website and
                        enhancements.
                      </p>
                      <p>
                        By using Google Analytics and our SEO tools, we will
                        make recommendations based on our analysis and
                        experience and create a personalised search engine
                        optimization plan for website success and your goals.
                      </p>
                      <p>
                        On the basis of the collected data, we respond and
                        react, modifying and updating the Websites if needed.
                      </p>
                    </AnimationOnScroll>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="digital-wrapper">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="digital-inner-wrapper">
                  <div className="digital-text">
                    <h3>On-site SEO.</h3>
                    <AnimationOnScroll animateIn="animate__fadeInUp">
                      <p>
                        Optimization of on-page search engines focuses on
                        improving design and content to feature the data that
                        users want. It is more than just adding keywords to
                        copy; you have to think about how a user’s journey is
                        affected by design and copy. To define a product or
                        service offering, a platform that focuses its content on
                        the end user will naturally integrate specific search
                        words.
                      </p>
                      <p>
                        As Google develops its algorithm, search is fluid and
                        constantly updated, so we continually learn and keep an
                        eye on opinion leaders in the industry so that we are
                        aware of developments, new strategies and ideas.
                      </p>
                      <p>
                        By analysing user intent and modifying content
                        accordingly, we take a user-centric approach to on-page
                        SEO. This means that we are continuously working to
                        create an improved experience that places your website
                        above the rest while following the guidelines to get our
                        webpage fundamentals correct.
                      </p>
                    </AnimationOnScroll>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="digital-img-section">
                  <AnimationOnScroll animateIn="animate__fadeIn">
                    <img
                      src={IMAGES.DMOnSiteSEO}
                      alt=""
                      className="digital-img1"
                    />
                  </AnimationOnScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Working PROCESS */}
        <div className="digital-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="digital-img-section">
                  <AnimationOnScroll animateIn="animate__fadeIn">
                    <img
                      src={IMAGES.DMImgWorking}
                      alt=""
                      className="digital-working-img"
                    />
                  </AnimationOnScroll>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="digital-text">
                  <h5>WORKING PROCESS</h5>
                  <h3>To thrive in business today, you’ll need a good plan</h3>

                  <div className="dm-que-wrapper">
                    <div className="main-que">
                      <div className="que-icon" onClick={() => toggleMenu(1)}>
                        <div>
                          {subMenuOpen === 1 ? (
                            <div>
                              <MdRemove size={30} color="#dd1717" />
                            </div>
                          ) : (
                            <div>
                              <MdAdd size={30} color="#dd1717" />
                            </div>
                          )}
                        </div>
                        <p>How can we help?</p>
                      </div>
                      {show && (
                        <p className="">
                          How many courses you’ve been on, using however many
                          techniques and methods. Unless you’ve come to realize
                          the value of mindset, then you’ve yet to understand
                          how success comes. With everyone
                        </p>
                      )}
                      {/* <div
                        className={`sub-menu ${
                          subMenuOpen === 1 ? "is-open" : ""
                        }`}
                      >
                        <div>
                          <p className="">
                            How many courses you’ve been on, using however many
                            techniques and methods. Unless you’ve come to
                            realize the value of mindset, then you’ve yet to
                            understand how success comes. With everyone
                          </p>
                        </div>
                      </div> */}
                    </div>
                    <div className="main-que">
                      <div className="que-icon" onClick={() => toggleMenu(2)}>
                        <div>
                          {subMenuOpen === 2 ? (
                            <div>
                              <MdRemove size={30} color="#dd1717" />
                            </div>
                          ) : (
                            <div>
                              <MdAdd size={30} color="#dd1717" />
                            </div>
                          )}
                        </div>
                        <p>Why would I need a business COACH?</p>
                      </div>

                      <div
                        className={`sub-menu ${
                          subMenuOpen === 2 ? "is-open" : ""
                        }`}
                      >
                        <div>
                          <p className="">
                            How many courses you’ve been on, using however many
                            techniques and methods. Unless you’ve come to
                            realize the value of mindset, then you’ve yet to
                            understand how success comes. With everyone
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="main-que">
                      <div className="que-icon" onClick={() => toggleMenu(3)}>
                        <div>
                          {subMenuOpen === 3 ? (
                            <div>
                              <MdRemove size={30} color="#dd1717" />
                            </div>
                          ) : (
                            <div>
                              <MdAdd size={30} color="#dd1717" />
                            </div>
                          )}
                        </div>
                        <p>What is one-on-one coaching?</p>
                      </div>

                      <div
                        className={`sub-menu ${
                          subMenuOpen === 3 ? "is-open" : ""
                        }`}
                      >
                        <div>
                          <p className="">
                            How many courses you’ve been on, using however many
                            techniques and methods. Unless you’ve come to
                            realize the value of mindset, then you’ve yet to
                            understand how success comes. With everyone
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* DM CARDS */}
        <div className="digital-wrapper dm-card">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="dm-card-section">
                  <div className="dm-main-card">
                    <img
                      src={IMAGES.DMHappyCustomer}
                      alt=""
                      className="happy-cust-img"
                    />
                    <h3>786</h3>
                    <p>Happy Customer</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="dm-card-section">
                  <div className="dm-main-card">
                    <img
                      src={IMAGES.DMExpertAdvisor}
                      alt=""
                      className="expert-adv-img"
                    />
                    <h3>50+</h3>
                    <p>Expert Advisor</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="dm-card-section">
                  <div className="dm-main-card">
                    <img
                      src={IMAGES.DMProjectCompleted}
                      alt=""
                      className="project-comp-img"
                    />
                    <h3>100+</h3>
                    <p>Project Completed</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* DM what we do */}
        <div className="digital-wrapper">
          <div className="container-fluid what-we-do-spacing">
            <div className="dm-bus-heading">
              <h5>WHAT WE DO</h5>
              <h3>We provide wide range of business services</h3>
            </div>
            <div className="dm-bus-wrapper">
              <div className="dm-bus-list-Section">
                <div className="dm-bus-list" onClick={funPartnerShow}>
                  <GiConversation size={25} />
                  <p>Marketing Communications</p>
                </div>
                <div className="dm-bus-list" onClick={funInnovationShow}>
                  <MdOutlineBusiness size={25} />
                  <p>New Business Innovation</p>
                </div>
                <div className="dm-bus-list" onClick={funStrategyShow}>
                  <SiMicrostrategy size={22} />
                  <p>Marketing Strategy</p>
                </div>
                <div className="dm-bus-list" onClick={funLeadShow}>
                  <GiRegeneration size={25} />
                  <p>Lead Generation</p>
                </div>
                <div className="dm-bus-list" onClick={funResearchShow}>
                  <GiArchiveResearch size={25} />
                  <p>Marketing Research</p>
                </div>
                <div className="dm-bus-list" onClick={funSupportShow}>
                  <MdContactSupport size={25} />
                  <p>Support Function</p>
                </div>
              </div>
              <div className="dm-bus-list-details">
                <div className="bus-details-wrapper">
                  {partnerShow ? (
                    <div className="dm-bus-details-wrapper">
                      <div className="bus-text">
                        <h5>Creative</h5>
                        <h3>Marketing Communications</h3>

                        <p>
                          The term "Marketing Communications" describes the
                          coordinated use of numerous marketing instruments and
                          platforms. The focus of marketing communication
                          channels is on how organisations convey a message to
                          their target market or to the market as a whole. It is
                          also in charge of the company's internal
                          communications.
                        </p>
                      </div>
                      <div className="bus-img-wrapper">
                        <img src={IMAGES.DMPartnership} alt="" width="300px" />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {InnovationShow ? (
                    <div className="dm-bus-details-wrapper">
                      <div className="bus-text">
                        <h5>Creative</h5>
                        <h3>New Business Innovation</h3>
                        <p>
                          New business innovation refers to the process of
                          developing and introducing new products, services,
                          processes, or business models that create value for
                          customers and differentiate a company from its
                          competitors. Innovation is crucial for the long-term
                          success and growth of a business, as it enables
                          companies to respond to changing market demands, stay
                          ahead of the competition, and create new markets.
                        </p>
                      </div>
                      <div className="bus-img-wrapper">
                        <img src={IMAGES.DMInnovation} alt="" width="300px" />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {StrategyShow ? (
                    <div className="dm-bus-details-wrapper">
                      <div className="bus-text">
                        <h5>Creative</h5>
                        <h3>Marketing Strategy</h3>
                        <p>
                          A marketing strategy is a long-term plan for attaining
                          a business' objectives through an understanding of
                          client needs and the development of a distinct and
                          long-lasting competitive advantage. It includes
                          everything, from choosing which channels to utilise to
                          contact your customers to figuring out who they are.
                        </p>
                      </div>
                      <div className="bus-img-wrapper">
                        <img src={IMAGES.DMStratergy} alt="" width="300px" />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {LeadShow ? (
                    <div className="dm-bus-details-wrapper">
                      <div className="bus-text">
                        <h5>Creative</h5>
                        <h3>Lead Generation</h3>
                        <p>
                          In order to improve future sales, lead creation
                          involves engaging potential consumers' interest. It is
                          an essential step in many businesses' sales processes.
                          In order to move leads through the sales funnel and
                          convert them into paying customers, it can be crucial
                          to understand lead generation and how to use it
                          successfully.
                        </p>
                      </div>
                      <div className="bus-img-wrapper">
                        <img src={IMAGES.DMLead} alt="" width="300px" />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {ResearchShow ? (
                    <div className="dm-bus-details-wrapper">
                      <div className="bus-text">
                        <h5>Creative</h5>
                        <h3>Marketing Research</h3>
                        <p>
                          A new service or product's viability is assessed
                          through market research, which involves interviews
                          with prospective customers. A corporation can use
                          market research to identify its target market and
                          learn more about the interests of its customers by
                          asking for their thoughts and other input.
                        </p>
                      </div>
                      <div className="bus-img-wrapper">
                        <img src={IMAGES.DMResearch} alt="" width="300px" />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {SupportShow ? (
                    <div className="dm-bus-details-wrapper">
                      <div className="bus-text">
                        <h5>Creative</h5>
                        <h3>Support Function</h3>
                        <p>
                          In a business organization, support functions refer to
                          the departments or teams that provide support and
                          assistance to the core business functions. These
                          support functions are often called "back-office"
                          functions as they operate behind the scenes and
                          support the company's day-to-day operations.
                        </p>
                      </div>
                      <div className="bus-img-wrapper">
                        <img src={IMAGES.DMFunction} alt="" width="300px" />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* DM About us */}
        <div className="digital-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="">
                  <div className="dm-about-text">
                    <h5>About Us</h5>
                    <h3>
                      We are a dynamic team of creative people of inovation &
                      marketing
                    </h3>
                    <AnimationOnScroll animateIn="animate__fadeInUp">
                      <p>
                        As a process transformation company, we rethinks and
                        rebuilds processes for the digital age by combining the
                        speed and insight of design thinking with the scale and
                        accuracy of data analytics. We rethink the process and
                        work together to streamline it, rebuild it, and deliver
                        it
                      </p>
                    </AnimationOnScroll>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="dm-about-col">
                        <h5>Boost Your Business</h5>
                        <AnimationOnScroll animateIn="animate__fadeInUp">
                          <p>
                            When it comes to sustainability and corporate
                            responsibility, we believe the normal rules of
                            business.
                          </p>
                        </AnimationOnScroll>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="dm-about-col">
                        <h5>Filtering Business Data</h5>
                        <AnimationOnScroll animateIn="animate__fadeInUp">
                          <p>
                            The industry has recognized us for our consistent en
                            deavors to provide end-to-end customized solutions
                          </p>
                        </AnimationOnScroll>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 dm-about-img">
                <AnimationOnScroll animateIn="animate__zoomIn">
                  <img
                    src={IMAGES.DMImgAbout}
                    alt=""
                    className="dm-dynamic-img"
                  />
                </AnimationOnScroll>
              </div>
            </div>
          </div>
        </div>
        <ServicesContact />
      </div>
    </>
  );
};

export default DigitalMarketing;
