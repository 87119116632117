import React from "react";
import "../Assets/Style/Registration.css";
import { useState, useEffect } from "react";
import { iucontact } from "../config/api";
const Registration = () => {
  const [state, setState] = useState({
    ContactID: 0,
    FirstName: "",
    LastName: "",
    EmailID: "",
    Mobile: "",
    SelectOption: "",
    Service: "",
    Products: "",
    Training: "",
    Message: "",
    CreatedBy: 0,
    errors: [],
  });
  const [service, setService] = useState(false);
  const [product, setProduct] = useState(false);
  const [training, setTraining] = useState(false);
  const handlechnageDrpOption = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      errors: { ...state.errors, [e.target.name]: "" },
    });
    if (e.target.value === "service") {
      setService(true);
      setProduct(false);
      setTraining(false);

      setState((state.Products = ""), (state.Training = ""));
      setState({ ...state, [e.target.name]: e.target.value });
    }
    if (e.target.value === "products") {
      setProduct(true);
      setTraining(false);
      setService(false);

      setState((state.Service = ""), (state.Training = ""));
      setState({ ...state, [e.target.name]: e.target.value });
    }
    if (e.target.value === "training") {
      setProduct(false);
      setTraining(true);
      setService(false);

      setState((state.Service = ""), (state.Products = ""));
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };

  const validate = () => {
    const errors = {};
    let IsValid = true;
    const regex_name = /^[A-Za-z\s]+$/;
    const regex_email = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const Mobilenumber = /^[7-9][0-9]/;

    if (!state.FirstName) {
      IsValid = false;
      errors["FirstName"] = "Full Name Is Required";
    } else if (state.FirstName.length < 2) {
      IsValid = false;
      errors["FirstName"] = "Must Be 15 Characters ";
    } else if (!state.FirstName.match(regex_name)) {
      IsValid = false;
      errors["FirstName"] = "Please Enter Valid Name";
    } else {
      errors["FirstName"] = "";
    }

    if (!state.Mobile) {
      IsValid = false;
      errors["Mobile"] = "Mobile number Is Required";
    } else if (state.Mobile.length < 10 || state.Mobile.length > 10) {
      IsValid = false;
      errors["Mobile"] = "Mobile Number Must Have 10 Digits";
    } else if (!state.Mobile.match(Mobilenumber)) {
      IsValid = false;
      errors["Mobile"] = "Please Enter valid Mobile Number";
    } else {
      errors["Mobile"] = "";
    }

    if (!state.EmailID) {
      IsValid = false;
      errors["EmailID"] = "Email ID Is Required";
    } else if (!state.EmailID.match(regex_email)) {
      IsValid = false;
      errors["EmailID"] = "Please Enter valid Email id";
    } else {
      errors["EmailID"] = "";
    }

    if (!state.SelectOption) {
      IsValid = false;
      errors["SelectOption"] = "Please Select Option ";
    } else {
      errors["SelectOption"] = "";
    }

    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };
  const clearState = () => {
    setState({
      ContactID: 0,
      FirstName: "",
      LastName: "",
      EmailID: "",
      Mobile: "",
      SelectOption: "",
      Service: "",
      Products: "",
      Training: "",
      Message: "",
      CreatedBy: 0,
    });
  };
  const handleSubmit = async () => {
    console.log("submit-->", state);
    if (validate()) {
      await iucontact(state).then((res) => {
        console.log("contact--->", res[0]);
        if (res.length == 1) {
          alert("You are Successfully Registed with us...!!");
          clearState();
        }
      });
    } else {
      alert("Please Enter Proper Details..!");
    }
  };
  return (
    <>
      <div className="registration">
        <div className="container">
          <div className="regContainer">
            <div className="regForm">
              <div className="registrationWrapper">
                  <div className="heading">
                    <h2>Register With us</h2>
                    <p>
                      Embrace the Opportunity For Success – Sign Up for Early
                      Registration
                    </p>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="inputWrapper">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Full Name"
                        aria-label="First Name"
                        name="FirstName"
                        value={state.FirstName}
                        onChange={handlechnageDrpOption}
                      />
                    </div>
                    {state.errors ? (
                      <p className="errors">{state.errors.FirstName}</p>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="inputWrapper">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email Id"
                        aria-label="email"
                        name="EmailID"
                        value={state.EmailID}
                        onChange={handlechnageDrpOption}
                      />
                    </div>
                    {state.errors ? (
                      <p className="errors">{state.errors.EmailID}</p>
                    ) : null}
                  </div>
                  <div className="col-lg-12">
                    <div className="inputWrapper">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Phone Number"
                        aria-label="Phone Number"
                        name="Mobile"
                        value={state.Mobile}
                        onChange={handlechnageDrpOption}
                      />
                    </div>
                    {state.errors ? (
                      <p className="errors">{state.errors.Mobile}</p>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="inputWrapper">
                      <select
                        className="form-select"
                        name="SelectOption"
                        onChange={handlechnageDrpOption}
                        value={state.SelectOption}
                      >
                        <option>-- Select Option --</option>
                        <option value="service">Services</option>
                        <option value="products">Products</option>
                        <option value="training">IT Training</option>
                      </select>
                    </div>
                  </div>
                </div>
                {service ? (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="inputWrapper">
                        <select
                          className="form-select"
                          name="Service"
                          value={state.Service}
                          onChange={handlechnageDrpOption}
                        >
                          <option value="selectType">
                            -- Select Services --
                          </option>
                          <option value="web design">Web Design</option>
                          <option value="web development">
                            Web development
                          </option>
                          <option value="digital marketing">
                            Digital Marketing
                          </option>
                          <option value="graphics design">
                            Graphics Design
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                ) : null}

                {product ? (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="inputWrapper">
                        <select
                          className="form-select"
                          name="Products"
                          value={state.Products}
                          onChange={handlechnageDrpOption}
                        >
                          <option value="selectType">
                            -- Select Products --
                          </option>
                          <option value=" School Management">
                            School Management
                          </option>
                          <option value="SPA Management">SPA Management</option>
                          <option value="Hospital Management">
                            Hospital Management
                          </option>
                          <option value="Inventory Management">
                            Inventory Management
                          </option>
                          <option value=" Payroll Management">
                            Payroll Management
                          </option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </div>
                  </div>
                ) : null}

                {training ? (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="inputWrapper">
                        <select
                          className="form-select"
                          name="Training"
                          value={state.Training}
                          onChange={handlechnageDrpOption}
                        >
                          <option value="selectType">
                            -- Select Training --
                          </option>
                          <option value="digital marketing">
                            Web Design Training
                          </option>
                          <option value="graphics design">
                            Web Development Training
                          </option>
                          <option value="web design">
                            Digital Marketing Training
                          </option>
                          <option value="web development">
                            Graphics Designing Training
                          </option>
                          <option value="staffing">Staffing</option>
                          <option value="recruitment">Recruitment</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="row">
                  <div className="col-lg-12">
                    <div className="inputWrapper">
                      <textarea
                        type="textarea"
                        className="form-control"
                        placeholder="Message..."
                        aria-label="Eessage"
                        name="Message"
                        value={state.Message}
                        onChange={handlechnageDrpOption}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="btnSubmit">
                      <button type="submit" className="" onClick={handleSubmit}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;
